import './SourceNewModal.css'
import React, { useState, useRef, useEffect } from 'react';
import { Modal, Row, Col, Form, Button, FloatingLabel } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRss, faList, faAlignJustify, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import Loading from 'components/utilities/Loading';
import api from '../../api.js';
import { toast } from 'react-toastify';

const SourceNewModal = ({ modal, setModal }) => {
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(1);
    const [sourceData, setSourceData] = useState({
        type: '',
        name: '',
        url: ''
    });

    const handleCloseNew = () => {
        setModal(false);
        setSourceData({ type: '', name: '', url: '' });
    }

    const handleSetSourceType = (type) => {
        setSourceData({ ...sourceData, type: type })
        setStep(2);
    }

    const handleBack = () => {
        setStep(1);
        setSourceData({ type: '', name: '', url: '' });
    }

    const handleSubmit = (e) => {
        console.log("Submit");
        e.preventDefault(); // To prevent the form from submitting by default

        setLoading(true);

        // Check if any field is set to 'default'
        if (sourceData.type === "" || sourceData.name === "" || sourceData.url === "") {
            toast.error("Please fill all required fields before submitting.", {
                theme: 'colored'
            });
            return;
        }

        api.post(`/api/sources`, sourceData)
            .then(response => {
                const success = response.status;
                if (success == 201) {
                    toast.success(`Created new source successfully`, {
                        theme: 'colored'
                    });
                    window.location.reload(true);
                }
                else {
                    toast.error(`Failed to create source.`, {
                        theme: 'colored'
                    });
                }
            })
            .catch(error => {
                console.error('Error creating source in:', error);
                toast.error(`Failed to create source.`, {
                    theme: 'colored'
                });
            })
            .finally(() => {
                setLoading(false);
            });

        // If everything is fine, proceed with your submission logic here
        console.log("Submit the form");
    };

    return (
        <Modal
            show={modal}
            onHide={handleCloseNew}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    New Source
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {step === 1 ? (
                    <Row className='py-2 px-4'>
                        <Col className='py-4 px-2' onClick={() => handleSetSourceType('RSS')}>
                            <div className="action-card">
                                <div className="header-icon-container">
                                    <div className="header-button">
                                        <FontAwesomeIcon icon={faRss} size="lg" className='icon' />
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <h4 className='card-title'>RSS Feed</h4>
                                    <p className='card-text'>Subscribes to a RSS feed to retrieve articles as they come in.</p>
                                    <div className="action-button-container">
                                        <div className="action-button">
                                            <FontAwesomeIcon icon={faChevronRight} className='icon' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col className='py-4 px-2' onClick={() => handleSetSourceType('DIRECTORY')}>
                            <div className="action-card">
                                <div className="header-icon-container">
                                    <div className="header-button">
                                        <FontAwesomeIcon icon={faList} size="lg" className='icon' />
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <h4 className='card-title'>Directory</h4>
                                    <p className='card-text'>Retrieves a list of articles from a directory page.</p>
                                    <div className="action-button-container">
                                        <div className="action-button">
                                            <FontAwesomeIcon icon={faChevronRight} className='icon' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col className='py-4 px-2' onClick={() => handleSetSourceType('CONTENT')}>
                            <div className="action-card">
                                <div className="header-icon-container">
                                    <div className="header-button">
                                        <FontAwesomeIcon icon={faAlignJustify} size="lg" className='icon' />
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <h4 className='card-title'>Content</h4>
                                    <p className='card-text'>Extract article directly from individual content page.</p>
                                    <div className="action-button-container">
                                        <div className="action-button">
                                            <FontAwesomeIcon icon={faChevronRight} className='icon' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>

                    </Row>
                ) : (
                    <>
                        <Form onSubmit={handleSubmit}>
                            <Row className='py-2 px-2 mb-3 g-3'>
                                <Col className="px-3" xs={12} md={12}>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Source Name"
                                        placeholder='BBC World'
                                    >
                                        <Form.Control
                                            type="text"
                                            value={sourceData.name}
                                            onChange={e => setSourceData({ ...sourceData, name: e.target.value })}
                                        />
                                    </FloatingLabel>
                                </Col>
                                <Col className="px-3" xs={12} md={12}>
                                    <FloatingLabel
                                        controlId="floatingInput"
                                        label="Source URL"
                                        placeholder='https://www.bbc.com/news/world/australia'
                                    >
                                        <Form.Control
                                            type="text"
                                            value={sourceData.url}
                                            onChange={e => setSourceData({ ...sourceData, url: e.target.value })}
                                        />
                                    </FloatingLabel>
                                </Col>

                            </Row>
                            <Modal.Footer className="justify-content-between">
                                <Button variant="secondary" onClick={handleBack}>
                                    Back
                                </Button>
                                <Button variant="primary" type="submit" disabled={loading}>
                                    Create Source <Loading loading={loading} />
                                </Button>
                            </Modal.Footer>
                        </Form>

                    </>
                )}

            </Modal.Body>
        </Modal>
    );
}

SourceNewModal.propTypes = {
    modal: PropTypes.bool.isRequired,
    setModal: PropTypes.func.isRequired,
};

export default SourceNewModal;
