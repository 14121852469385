import React, { useState, useEffect, useContext } from 'react';
import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import { Link, useNavigate } from 'react-router-dom';
import ContentSendModal from './modals/ContentSendModal';
import ContentDeleteModal from './modals/ContentDeleteModal';
import { ContentViewContext } from 'context/Context';

const ContentViewBreadcrumbs = ({ content }) => {
    const [showSendModal, setShowSendModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const navigate = useNavigate();

    const { handleEdit } = useContext(ContentViewContext);

    return (
        <>
            <ContentSendModal showSendModal={showSendModal} setShowSendModal={setShowSendModal} />
            <ContentDeleteModal showDeleteModal={showDeleteModal} setShowDeleteModal={setShowDeleteModal} />
            <Card className="mb-3">
                <Card.Header className="d-flex flex-between-center">
                    <IconButton
                        onClick={() => navigate(-1)}
                        variant="falcon-default"
                        size="sm"
                        icon="arrow-left"
                    />
                    <Flex>
                        <IconButton
                            variant="falcon-default"
                            size="sm"
                            icon="paper-plane"
                            transform="shrink-2"
                            iconAlign="middle"
                            onClick={setShowSendModal}
                            className="me-2"
                        >
                            <span className="d-none d-xl-inline-block ms-1">Send</span>
                        </IconButton>
                        <IconButton
                            variant="falcon-default"
                            size="sm"
                            icon="edit"
                            transform="shrink-2"
                            iconAlign="middle"
                            className="me-2"
                            onClick={() => handleEdit(content.id)}
                        >
                            <span className="d-none d-xl-inline-block ms-1">Edit</span>
                        </IconButton>
                        <IconButton
                            variant="falcon-default"
                            size="sm"
                            icon="trash-alt"
                            iconAlign="middle"
                            onClick={setShowDeleteModal}
                            className="d-none d-sm-block me-2"
                        >
                            <span className="d-none d-xl-inline-block ms-1">Delete</span>
                        </IconButton>
                        {content.rawContentId != 0 ? (
                            <IconButton
                                className="me-2"
                                variant="falcon-default"
                                size="sm"
                                icon="eye"
                                as={Link}
                                to={content.rawContentUrl}
                                target="new"
                            >
                                View Article
                            </IconButton>

                        ) : null}
                    </Flex>
                </Card.Header>
            </Card>
        </>
    );
};

ContentViewBreadcrumbs.propTypes = {
    content: PropTypes.object.isRequired
};

export default ContentViewBreadcrumbs;
