import React, { useState, useEffect, useRef } from 'react';
import { Form, Row, Col, Button, FloatingLabel } from 'react-bootstrap';
import FalconComponentCard from 'components/common/FalconComponentCard';
import api from '../../api.js';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const SourceEdit = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [sourceData, setSourceData] = useState({
        type: '',
        name: '',
        url: ''
    });

    const handleCancel = (e) => {
        e.preventDefault(); // To prevent the form from submitting by default
        navigate('/sources');
    }

    const handleSubmit = (e) => {
        console.log("Submit");
        e.preventDefault(); // To prevent the form from submitting by default

        // Check if any field is set to 'default'
        if (sourceData.name === "" || sourceData.url === "") {
            toast.error("Please fill all required fields before submitting.", {
                theme: 'colored'
            });
            return;
        }

        api.put(`/api/sources/${id}`, sourceData)
            .then(response => {
                const success = response.status;
                if (success == 200) {
                    toast.success(`Updated source successfully`, {
                        theme: 'colored'
                    });
                    navigate('/sources');
                }
                else {
                    toast.error(`Failed to create source.`, {
                        theme: 'colored'
                    });
                }
            })
            .catch(error => {
                console.error('Error creating source in:', error);
            })

        // If everything is fine, proceed with your submission logic here
        console.log("Submit the form");
    };

    useEffect(() => {
        api.get(`/api/sources/${id}`)
            .then(response => {
                const success = response.status;
                if (success == 200) {
                    setSourceData(response.data);
                    toast.success(`Grabbed source successfully`, {
                        theme: 'colored'
                    });
                }
                else {
                    toast.error(`Failed to grab source.`, {
                        theme: 'colored'
                    });
                }
            })
            .catch(error => {
                console.error('Error grabbing source in:', error);
            });
    }, [id]);

    return (
        <FalconComponentCard>
            <FalconComponentCard.Header title="Edit Source" light={false} noPreview={true}>
                <p className="mt-2 mb-0">
                    Send content and optimize your workflow.
                </p>
            </FalconComponentCard.Header>
            <FalconComponentCard.Body>
                <Form onSubmit={handleSubmit}>
                    <Row className='m-3 g-3' style={{ paddingBottom: '20px', marginBottom: '50px' }}>
                        <Col className="px-3" xs={12} md={12}>
                            <h4>Source Settings</h4>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Source Name"
                                placeholder='BBC World'
                            >
                                <Form.Control
                                    type="text"
                                    value={sourceData.name}
                                    onChange={e => setSourceData({ ...sourceData, name: e.target.value })}
                                />
                            </FloatingLabel>
                        </Col>
                        <Col className="px-3" xs={12} md={12}>
                            <FloatingLabel controlId="floatingSelect" label="Type">
                                <Form.Select value={sourceData.type} onChange={e => setSourceData({ ...sourceData, type: e.target.value })}>
                                    <option value="RSS">RSS</option>
                                    <option value="DIRECTORY">Directory</option>
                                    <option value="CONTENT">Content</option>
                                </Form.Select>
                            </FloatingLabel>
                        </Col>
                        <Col className="px-3" xs={12} md={12}>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Source URL"
                                placeholder='https://www.bbc.com/news/world/australia'
                            >
                                <Form.Control
                                    type="text"
                                    value={sourceData.url}
                                    onChange={e => setSourceData({ ...sourceData, url: e.target.value })}
                                />
                            </FloatingLabel>
                        </Col>
                    </Row>

                    <Button variant="secondary" onClick={handleCancel} className='mx-1'>
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit" className='mx-1'>
                        Update Source
                    </Button>
                </Form>
            </FalconComponentCard.Body>
        </FalconComponentCard >
    );
};

export default SourceEdit;
