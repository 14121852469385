import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

const Loading = ({ loading = false, children, size = '1x', isCenter = false }) => {
    return (
        loading ? (
            isCenter ? (
                <div className='text-center'>
                    <FontAwesomeIcon icon={faCircleNotch} className='fa-spin' size={size} />
                </div >
            ) : (
                <FontAwesomeIcon icon={faCircleNotch} className='fa-spin' size={size} />
            )
        ) : (children)
    );
};

Loading.propTypes = {
    children: PropTypes.any
};

export default Loading;