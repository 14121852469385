import React from 'react';
import PropTypes from 'prop-types';
import { Col, Button } from 'react-bootstrap';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SoftBadge from 'components/common/SoftBadge';
import api from '../../api.js';

const PricingDefaultCard = ({
  pricing: {
    title,
    type,
    subTitle,
    price,
    buttonText,
    isFeatured,
    featureTitle,
    features
  }
}) => {

  const handleManageCheckoutb = (productName) => {
    if (type != 'ENTERPRISE') {
      api.post('/api/stripe/create-checkout-session', { subscriptionProduct: type })
        .then(response => {
          const success = response.data.success;
          if (success) {
            window.location.replace(response.data.data[0].url);
          }
        })
        .catch(error => {
          console.error('Error grabbing fetches:', error);
        });
    } else {
      window.location.href = "mailto:admin@syndica.ai";
    }
  }

  return (
    <Col
      lg={4}
      className={classNames('border-top border-bottom', {
        'dark__bg-1000 px-4 px-lg-0': isFeatured
      })}
      style={{ backgroundColor: isFeatured && 'rgba(115, 255, 236, 0.18)' }}
    >
      <div className="h100">
        <div className="text-center p-4">
          <h3 className="fw-normal my-0">{title}</h3>
          <p className="mt-3">{subTitle}</p>
          <h2 className="fw-medium my-4">
            {type == 'ENTERPRISE' ? "" : (
              <>
                <sup className="fw-normal fs-2 me-1">$</sup>
                {price}
                <small className="fs--1 text-700">/ year</small>
              </>
            )}

          </h2>
          <Button
            variant={isFeatured ? 'primary' : 'outline-primary'}
            onClick={handleManageCheckoutb}
          >
            {buttonText}
          </Button>
        </div>
        <hr className="border-bottom-0 m-0" />
        <div className="text-start px-sm-4 py-4">
          <h5 className="fw-medium fs-0">{featureTitle}</h5>
          <ul className="list-unstyled mt-3">
            {features.map(feature => (
              <li className="py-1" key={feature.id}>
                <FontAwesomeIcon icon="check" className="me-2 text-success" />{' '}
                {feature.title}{' '}
                {feature.tag && (
                  <SoftBadge pill bg={feature.tag.type}>
                    {feature.tag.label}
                  </SoftBadge>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Col>
  );
};

PricingDefaultCard.propTypes = {
  pricing: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
    price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    url: PropTypes.string,
    buttonText: PropTypes.string,
    isFeatured: PropTypes.bool,
    featureTitle: PropTypes.string,
    features: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        tag: PropTypes.shape({
          label: PropTypes.string,
          type: PropTypes.string
        })
      })
    )
  })
};

export default PricingDefaultCard;
