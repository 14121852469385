import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import React from 'react';
import { Col, Form, FormCheck, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const PricingDefaultHeader = () => {
  return (
    <Col xs={12} className="mb-3">
      <Row className="align-items-center justify-content-center justify-content-sm-between">
        <Col sm="auto" className="text-center">
          <h5 className="d-inline-block">Subscription Plans</h5>
        </Col>
        <Col
          sm="auto"
          as={Flex}
          alignItems="center"
          className="mt-1 mt-sm-0 justify-content-center"
        >
          <Link to="/Logout">Logout</Link>
        </Col>
      </Row>
    </Col>
  );
};
export default PricingDefaultHeader;
