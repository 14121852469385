import React from 'react';
import { Row, Col, Card, FloatingLabel, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faBars } from '@fortawesome/free-solid-svg-icons';
import { Draggable } from 'react-beautiful-dnd';
import { createPortal } from 'react-dom';

const TagItem = ({ item, index, currentDraggedItemId, handleTagChange, handleConditionChange, handleOperationChange, deleteItem }) => {
    return (
        <Draggable
            draggableId={item.id}
            index={index}
        >
            {(provided, snapshot) => {
                const shouldUsePortal = snapshot.isDragging;

                const child = (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={item.id == currentDraggedItemId ? 'dragging' : ''}>
                        <Card className="mb-3 kanban-item shadow-sm dark__bg-1100">
                            <Card.Body>
                                <Row>
                                    <Col>
                                        <FloatingLabel controlId="floatingSelectCondition" label="Condition">
                                            <Form.Select value={item.condition} onChange={(e) => handleConditionChange(item.id, e.target.value)}>
                                                <option value="INCLUDE">Include</option>
                                                <option value="EXCLUDE">Exclude</option>
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Col>
                                    <Col>
                                        <FloatingLabel
                                            controlId="floatingInput"
                                            label="Tag Name"
                                        >
                                            <Form.Control
                                                type="text"
                                                value={item.tag}
                                                onChange={(e) => handleTagChange(item.id, e.target.value)}
                                            />
                                        </FloatingLabel>
                                    </Col>
                                    <Col>
                                        <FloatingLabel controlId="floatingSelectOperation" label="Operation">
                                            <Form.Select value={item.operation} onChange={(e) => handleOperationChange(item.id, e.target.value)}>
                                                <option value="NONE">None</option>
                                                <option value="AND">AND</option>
                                                <option value="OR">OR</option>
                                            </Form.Select>
                                        </FloatingLabel>
                                    </Col>
                                    <Col xs="auto" className="d-flex align-items-center">
                                        <div
                                            style={{
                                                width: '32px',
                                                height: '32px',
                                                borderRadius: '50%',
                                                backgroundColor: '#ff0e7',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                marginRight: '10px', // This ensures some space between the two icons
                                                cursor: 'pointer'
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faBars} />
                                        </div>
                                        <div
                                            style={{
                                                width: '32px',
                                                height: '32px',
                                                borderRadius: '50%',
                                                backgroundColor: '#ff0e7',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => deleteItem(item.id)}
                                        >
                                            <FontAwesomeIcon icon={faTrash} />
                                        </div>
                                    </Col>

                                </Row>
                            </Card.Body>
                        </Card>
                    </div>
                );

                return shouldUsePortal
                    ? createPortal(child, document.getElementById('dnd-portal'))
                    : child;
            }}
        </Draggable>

    );
}

export default TagItem;