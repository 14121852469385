import React, { useState, useEffect } from 'react';
import api from 'api.js';
import { toast } from 'react-toastify';
import { Card, Col, Row, Spinner, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PricingDefaultHeader from 'components/pricing/PricingDefaultHeader';
import PricingDefaultCard from 'components/pricing/PricingDefaultCard';
import { pricingData } from 'data/pricingData';

const SubscriptionModal = () => {
    const [loading, setLoading] = useState(true);
    const [prices, setPrices] = useState([]);
    const [subscription, setSubscription] = useState({});
    const [showModal, setShowModal] = useState(false);

    const fetchSubscription = () => {
        api.get(`/api/subscriptions`)
            .then(response => {
                const success = response.status;
                if (success == 200) {
                    setSubscription(response.data);
                    if (response.data.status == 'INACTIVE') {
                        setShowModal(true);
                        fetchPriceList();
                    }
                }
                else {
                    toast.error(`Failed to create source.`, {
                        theme: 'colored'
                    });
                }
            })
            .catch(error => {
                console.error('Error creating source in:', error);
            });
    };

    const fetchPriceList = () => {
        api.get(`/api/stripe/list-prices`)
            .then(response => {
                const success = response.status;
                if (success == 200) {
                    pricingData.forEach(plan => {
                        const match = response.data.prices.find(externalPrice => externalPrice.type === plan.type);
                        if (match) {
                            if (plan.type == 'ENTERPRISE') {
                                plan.price = "Custom Pricing";
                            } else {
                                plan.price = match.price;
                            }

                        }
                    });
                    setPrices(pricingData);
                    setLoading(false);
                }
                else {
                    toast.error(`Failed to create source.`, {
                        theme: 'colored'
                    });
                }
            })
            .catch(error => {
                console.error('Error creating source in:', error);
            });
    };

    useEffect(() => {
        fetchSubscription();
    }, []);

    return (
        <>
            <Modal show={showModal} dialogClassName="modal-90w" backdrop="static" keyboard={false}>
                <Modal.Body>

                    <Row className="g-0">
                        <PricingDefaultHeader />
                        {loading ? (
                            <Col xs={12} className="py-4">
                                <Spinner
                                    className="position-absolute start-50"
                                    animation="grow"
                                />
                            </Col>
                        ) : (
                            prices.map(price => (
                                <PricingDefaultCard key={price.id} pricing={price} />
                            ))
                        )}
                        <Col xs={12} className="text-center">
                            <h5 className="mt-3">
                                Creating and syndicating content through AI.
                            </h5>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default SubscriptionModal;
