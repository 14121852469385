import React, { useState, useEffect, useRef } from 'react';
import { Form, Row, Col, Button, Card } from 'react-bootstrap';
import FalconComponentCard from 'components/common/FalconComponentCard';
import api from '../../api.js';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Loading from 'components/utilities/Loading';
import SourceSelector from './SourceSelector';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
const ActionHandlerContext = React.createContext();
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';

const SourceGroupEdit = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [isLoading, setLoading] = useState(false);
    const [sourceGroupData, setSourceGroupData] = useState({});
    const [items, setItems] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);

    const handleCancel = (e) => {
        e.preventDefault(); // To prevent the form from submitting by default
        navigate('/source-groups');
    }

    const handleSubmit = (e) => {
        e.preventDefault(); // To prevent the form from submitting by default

        // Check if any field is set to 'default'
        if (sourceGroupData.name === "default") {
            toast.error("Please select all required fields before submitting.", {
                theme: 'colored'
            });
            return;
        }

        const sourceIds = selectedItems.map(item => item.value);

        const payload = {
            ...sourceGroupData,
            sourceIds: sourceIds
        };

        setLoading(true);
        api.put(`/api/source-groups/${id}`, payload)
            .then(response => {
                const success = response.status;
                if (success == 200) {
                    toast.success(`Updated query successfully`, {
                        theme: 'colored'
                    });
                    navigate('/source-groups');
                }
                else {
                    toast.error(`Failed to update query.`, {
                        theme: 'colored'
                    });
                }
            })
            .catch(error => {
                console.error('Error updating query in:', error);
            })
            .finally(() => {
                setLoading(false);
            });

        // If everything is fine, proceed with your submission logic here
        console.log("Submit the form");
    };

    useEffect(() => {
        setLoading(true);
        const fetchSources = api.get('/api/sources/all')
            .then(response => {
                const success = response.data.success;
                if (success) {
                    const formattedItems = response.data.data.map(item => ({
                        value: item.id,
                        label: item.name,
                        sourceViewType: item.sourceViewType
                    }));
                    setItems(formattedItems);
                }
            })
            .catch(error => {
                console.error('Error grabbing sources:', error);
            });

        const fetchSourceGroup = api.get(`/api/source-groups/${id}`)
            .then(response => {
                const success = response.status;
                if (success === 200) {
                    setSourceGroupData(response.data);
                    setSelectedItems(response.data.sources.map(item => ({
                        value: item.id,
                        label: item.name,
                        sourceViewType: item.sourceViewType
                    })));
                    toast.success(`Grabbed query successfully`, {
                        theme: 'colored'
                    });
                } else {
                    toast.error(`Failed to grab query.`, {
                        theme: 'colored'
                    });
                }
            })
            .catch(error => {
                console.error('Error grabbing query:', error);
            });

        Promise.all([fetchSources, fetchSourceGroup])
            .finally(() => {
                setLoading(false);
            });
    }, [id]);

    return (
        <FalconComponentCard>
            <FalconComponentCard.Header title="Edit Source Group" light={false} noPreview={true}>
                <p className="mt-2 mb-0">
                    Syndica will group sources together based on the criteria you provide. This will help you to manage your sources more effectively.
                </p>
            </FalconComponentCard.Header>
            <FalconComponentCard.Body>
                <Loading loading={isLoading} isCenter={true} size="lg">
                    <Form onSubmit={handleSubmit}>
                        <Row className='m-3 g-3'>
                            <Col className="px-3" xs={12} md={6}>
                                <h4>General</h4>
                                <Form.Group className="mb-3" controlId="projectName">
                                    <Form.Label>Name *</Form.Label>
                                    <Form.Control
                                        type="text"
                                        required={true}
                                        placeholder="eg. Latest News"
                                        value={sourceGroupData.name || ''}
                                        onChange={e => setSourceGroupData({ ...sourceGroupData, name: e.target.value })}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <SourceSelector items={items}
                            selectedItems={selectedItems}
                            setSelectedItems={setSelectedItems} />

                        <Button variant="secondary" onClick={handleCancel} className='mx-1'>
                            Cancel
                        </Button>
                        <Button variant="primary" type="submit" className='mx-1'>
                            Update Source Group
                        </Button>
                    </Form>
                </Loading>
            </FalconComponentCard.Body>
        </FalconComponentCard >
    );
};

export default SourceGroupEdit;
