import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form, Row, Col, Alert } from 'react-bootstrap';
import api from '../../api.js';
import { useNavigate } from 'react-router-dom';

const RegistrationForm = ({ hasLabel }) => {
  // State
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    acceptTermsAndConditions: false
  });
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // Handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email, password, confirmPassword, acceptTermsAndConditions } = formData;

    if (password !== confirmPassword) {
      setError("Passwords don't match.");
      return;
    }
    try {
      api.post('/api/register', { email, password, acceptTermsAndConditions })
        .then(response => {
          const status = response.data.status;
          toast.success(`Successfully registered!`, {
            theme: 'colored'
          });
          if (status) {
            // Redirect the user to the dashboard
            navigate('/login');
          }
          else {
            setError(response.data.message);
            toast.error(`Failed to register.`, {
              theme: 'colored'
            });
          }

        })
        .catch(error => {
          console.error('Registration failed:', error);
          setError(error.response.data.message);
          toast.error(`Failed to register.`, {
            theme: 'colored'
          });
        });
    } catch (err) {
      setError('Registration failed.');
      console.log(err);
    }

  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      {error && (
        <Alert variant="danger" className="mt-3">
          {error}
        </Alert>
      )}
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Email address' : ''}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="text"
        />
      </Form.Group>

      <Row className="g-2 mb-3">
        <Form.Group as={Col} sm={6}>
          {hasLabel && <Form.Label>Password</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? 'Password' : ''}
            value={formData.password}
            name="password"
            onChange={handleFieldChange}
            type="password"
          />
        </Form.Group>
        <Form.Group as={Col} sm={6}>
          {hasLabel && <Form.Label>Confirm Password</Form.Label>}
          <Form.Control
            placeholder={!hasLabel ? 'Confirm Password' : ''}
            value={formData.confirmPassword}
            name="confirmPassword"
            onChange={handleFieldChange}
            type="password"
          />
        </Form.Group>
      </Row>

      <Form.Group className="mb-3">
        <Form.Check type="checkbox" id="acceptCheckbox" className="form-check">
          <Form.Check.Input
            type="checkbox"
            name="acceptTermsAndConditions"
            checked={formData.acceptTermsAndConditions}
            onChange={e =>
              setFormData({
                ...formData,
                acceptTermsAndConditions: e.target.checked
              })
            }
          />
          <Form.Check.Label className="form-label">
            I accept the <Link to="#!">terms</Link> and{' '}
            <Link to="#!">privacy policy</Link>
          </Form.Check.Label>
        </Form.Check>
      </Form.Group>

      <Form.Group>
        <Button
          className="w-100"
          type="submit"
          disabled={
            !formData.email ||
            !formData.password ||
            !formData.confirmPassword ||
            !formData.acceptTermsAndConditions
          }
        >
          Register
        </Button>
      </Form.Group>
    </Form>
  );
};

RegistrationForm.propTypes = {
  hasLabel: PropTypes.bool
};

export default RegistrationForm;
