import React from 'react';
import { Col, Row, Button, Card } from 'react-bootstrap';
import Moment from 'react-moment';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';

const NuggetLayout = ({ data }) => {

    const extractFirstParagraph = (html) => {
        const words = html.split(/\s+/);
        return words.slice(0, 30).join(' ') + '...';
    };

    return (
        <div className="d-flex flex-column gap-3">
            <Row className="align-items-start mb-3">
                {data.length == 0 ? (
                    <div className='text-center'>
                        <h2>No items found.</h2>
                    </div>
                ) : data.slice(0, 12).map((content, index) => (


                    <Col xs={12} md={6} lg={4} key={index} className="mb-4">
                        <Card className="h-100">
                            {content.imageUrl && <Card.Img src={content.imageUrl} variant='top' />}
                            <Card.Body>
                                <Card.Title as='h5'>
                                    {content.title}
                                </Card.Title>
                                <Card.Text>
                                    {content.contentPlain ? parse(DOMPurify.sanitize(extractFirstParagraph(content.contentPlain))) : <p>Loading content...</p>}

                                </Card.Text>
                                <hr style={{ borderStyle: 'dashed' }} />
                                <Row>
                                    <Col xs={12} md={6} lg={4}>
                                        <div style={{ borderRight: 'solid 1px #ced4da' }}>
                                            <h6 className='card-meta-header'>TAGS MATCHED:</h6>
                                            <h6 className='card-meta-info'>{content.matchedIncludeTags[0]}</h6>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} lg={4}>
                                        <div style={{ borderRight: 'solid 1px #ced4da' }}>
                                            <h6 className='card-meta-header'>RESULT CREATED:</h6>
                                            <h6 className='card-meta-info'><Moment fromNow>{content.createdAt}</Moment></h6>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} lg={4}>
                                        <div>
                                            <h6 className='card-meta-header'>SOURCE PUBLISHED:</h6>
                                            <h6 className='card-meta-info'><Moment fromNow>{content.sourcePublishedAt}</Moment></h6>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                            <div className="p-3" style={{ backgroundColor: '#f3f2f7', borderBottomLeftRadius: '6px', borderBottomRightRadius: '6px' }}>
                                <Row>
                                    <Col className="d-flex justify-content-center">
                                        <Button className='mx-2' variant="outline-primary" size="sm" href={`/content/${content.id}`}>
                                            VIEW CONTENT
                                        </Button>
                                    </Col>

                                </Row>
                            </div>


                        </Card >
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default NuggetLayout;