import React, { useState, useRef, useEffect } from 'react';
import { Card, Dropdown, Modal, Button } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import ToneOfVoiceListTableHeader from './ToneOfVoiceListTableHeader';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import api from '../../api.js';
import { toast } from 'react-toastify';
import IconButton from 'components/common/IconButton';
import SoftBadge from 'components/common/SoftBadge';
import { useNavigate } from 'react-router-dom';
import Loading from 'components/utilities/Loading';
import Moment from 'react-moment';

const ActionHandlerContext = React.createContext();

const ToneOfVoiceList = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [activeFetch, setActiveFetch] = useState(null);
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(10);
    const [sortBy, setSortBy] = useState('name');
    const [direction, setDirection] = useState('asc');
    const [filter, setFilter] = useState('');
    const [totalPages, setTotalPages] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [showDelete, setShowDelete] = useState(false);

    const [activeToneOfVoice, setActiveToneOfVoice] = useState(null);

    const handleCloseDelete = () => {
        setShowDelete(false);
        setActiveToneOfVoice(null);
    }

    const handleShowDelete = (toneOfVoiceId) => {
        setShowDelete(true);
        setActiveToneOfVoice(toneOfVoiceId);
    }

    const handleEdit = async (toneOfVoiceId) => {
        if (toneOfVoiceId !== null) {
            navigate(`/tone-of-voice/${toneOfVoiceId}/edit`);
        }
    };

    const handleLoadQueries = (page = 0, size = 10, sortBy = 'name', direction = 'asc', filter = '') => {
        setLoading(true);
        api.get('/api/tone-of-voice', { params: { page, size, sortBy, direction, filter } })
            .then(response => {
                const success = response.data.success;
                setLoading(false);
                if (success) {
                    setToneOfVoices(response.data.data);
                    setTotalPages(response.data.totalPages);
                    setTotalElements(response.data.totalElements);
                    updateQueries(response.data.data);
                } else {
                    toast.error('Failed to grab tone of voices.', { theme: 'colored' });
                }
            })
            .catch(error => {
                setLoading(false);
                console.error('Error grabbing queries:', error);
                if (error.response && error.response.status === 401) {
                    navigate('/login');
                }
            });
    };

    // const loadData = () => {
    //     api.get('/api/tone-of-voice')
    //         .then(response => {
    //             const success = response.data.success;
    //             setLoading(false);
    //             if (success) {
    //                 setToneOfVoicees(response.data.data);
    //             }
    //             else {
    //                 toast.error(`Failed to grab queries.`, {
    //                     theme: 'colored'
    //                 });
    //             }
    //         })
    //         .catch(error => {
    //             setLoading(false);
    //             console.error('Error grabbing queries in:', error);
    //             if (error.response && error.response.status === 401) {
    //                 // Redirect to login page on 401 status
    //                 navigate('/login');
    //             }
    //         });
    // }

    const handleDelete = async () => {
        setShowDelete(false);
        if (activeToneOfVoice !== null) {
            api.delete(`/api/tone-of-voice/${activeToneOfVoice}`)
                .then(response => {
                    if (response.status === 204) {
                        console.log("Tone of voice deleted successfully");
                        toast.success(`Deleted tone of voice succesfully.`, {
                            theme: 'colored'
                        });
                        // Filter out the deleted toneOfVoice and update state
                        const updatedToneOfVoicees = toneOfVoices.filter(toneOfVoice => toneOfVoice.id !== activeToneOfVoice);
                        setToneOfVoices(updatedToneOfVoicees);
                    }

                })
                .catch(error => {
                    console.error("There was an error deleting the tone of voice:", error);
                });
        }
    };

    const [toneOfVoices, setToneOfVoices] = useState([]);

    useEffect(() => {
        handleLoadQueries(page, size, sortBy, direction, filter);
    }, [page, size, sortBy, direction, filter]);

    const handleSort = (column) => {
        const isAsc = sortBy === column && direction === 'asc';
        setDirection(isAsc ? 'desc' : 'asc');
        setSortBy(column);
    };

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const handlePageSizeChange = (newSize) => {
        setSize(newSize);
    };

    const columns = [
        {
            accessor: 'name',
            Header: 'Name',
            headerProps: { className: 'pe-1' },
            cellProps: {
                className: 'py-2'
            },
            Cell: rowData => {
                const { name, sourceName, sourceImageUrl } = rowData.row.original;
                return (
                    <Flex alignItems="center">
                        <div className="flex-1">
                            <h5 className="mb-0 fs--1">{name}</h5>
                        </div>
                    </Flex>
                );
            }
        },
        {
            accessor: 'status',
            Header: 'Status',
            Cell: rowData => {
                const { status } = rowData.row.original;
                return <span style={{ textTransform: 'capitalize' }}>{status.toLowerCase()}</span>;
            }
        },
        {
            accessor: 'none',
            Header: '',
            disableSortBy: true,
            cellProps: {
                className: 'text-end'
            },
            Cell: rowData => {
                const { id } = rowData.row.original;
                const { handleEdit, handleShowDelete } = React.useContext(ActionHandlerContext)
                return (
                    <>
                        <IconButton
                            variant="falcon-default"
                            size="sm"
                            icon="edit"
                            transform="shrink-3"
                            className="mx-1"
                            onClick={() => {
                                handleEdit(id);
                            }}
                        >
                            <span className="d-none d-sm-inline-block ms-1">Edit</span>
                        </IconButton>
                        <IconButton
                            variant="danger"
                            size="sm"
                            icon="trash"
                            transform="shrink-3"
                            className="mx-1"
                            onClick={() => {
                                handleShowDelete(id);
                            }}
                        >
                            <span className="d-none d-sm-inline-block ms-1">Delete</span>
                        </IconButton>
                    </>
                );
            }
        }
    ];

    return (
        <>
            <Modal show={showDelete} onHide={handleCloseDelete} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this item? This action cannot be undone. Please ensure you have saved any necessary data related to this item before proceeding.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleDelete}>
                        Delete
                    </Button>
                    <Button variant="primary" onClick={handleCloseDelete}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <ActionHandlerContext.Provider value={{ handleEdit, handleShowDelete }}>
                <AdvanceTableWrapper
                    columns={columns}
                    data={toneOfVoices}
                    pagination
                    perPage={size}
                    setPageSize={handlePageSizeChange}
                    pageIndex={page}
                    setPage={handlePageChange}
                    sortBy={sortBy}
                    setSortBy={setSortBy}
                    sortDirection={direction}
                    setSortDirection={setDirection}
                    globalFilter={filter}
                >
                    <Card className="mb-3">
                        <Card.Header>
                            <ToneOfVoiceListTableHeader table loadData={handleLoadQueries} />
                        </Card.Header>
                        <Card.Body className="bg-light">
                            <Loading loading={loading} isCenter={true} size="lg">
                                <AdvanceTable
                                    table
                                    headerClassName="bg-200 text-900 text-nowrap align-middle"
                                    rowClassName="align-middle white-space-nowrap"
                                    tableProps={{
                                        bordered: true,
                                        striped: true,
                                        className: 'fs--1 mb-0'
                                    }}
                                />
                            </Loading>
                        </Card.Body>
                        <Card.Footer>
                            <AdvanceTablePagination
                                canPreviousPage={page > 0}
                                canNextPage={page < totalPages - 1}
                                previousPage={() => handlePageChange(page - 1)}
                                nextPage={() => handlePageChange(page + 1)}
                                pageCount={totalPages}
                                pageIndex={page}
                                gotoPage={handlePageChange}
                                totalElements={totalElements}
                            />
                        </Card.Footer>
                    </Card>
                </AdvanceTableWrapper>
            </ActionHandlerContext.Provider>
        </>
    );
};

export default ToneOfVoiceList;
