import React from 'react';
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import './tagsinput.css'

class TagInput extends React.Component {
    render() {
        return (
            <TagsInput
                value={this.props.value}
                onChange={this.props.onChange}
                addOnBlur={true}
                inputProps={{ placeholder: this.props.placeholder }}
            />
        );
    }
}

export default TagInput;