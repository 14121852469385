import freeIcon from 'assets/img/icons/free.svg';
import proIcon from 'assets/img/icons/pro.svg';

export const pricingData = [
    {
        id: 0,
        type: "LITE",
        title: 'Syndica Lite',
        subTitle: 'For content producers, marketers & freelancers who want on-brand content published to their marketing channels.',
        price: 0,
        buttonText: 'Get Lite',
        isFeatured: false,
        featureTitle: 'Lite plan includes:',
        features: [
            { id: 1, title: '1 Seat' },
            { id: 2, title: '1 Brand Voice' },
            { id: 3, title: '25 Content Generation Queries/Day' },
            { id: 4, title: '7 Content Formats' },
            { id: 5, title: 'Real Time Content Editor' },
            { id: 6, title: '5 Content Distribution Integrations' },
        ]
    },
    {
        id: 1,
        type: "BUSINESS",
        title: 'Syndica Business',
        subTitle: 'For content and marketing teams who want to maximise quality and quantity of on brand content distributed across their audience channels.',
        price: 0,
        buttonText: 'Get Business',
        isFeatured: true,
        featureTitle: 'Business plan includes:',
        features: [
            { id: 1, title: '2 Seats' },
            { id: 2, title: '2 Brand Voices' },
            { id: 3, title: '50 Content Generation Queries/Day' },
            { id: 4, title: '7 Content Formats' },
            { id: 5, title: 'Real Time Content Editor' },
            { id: 6, title: '10 Content Distribution Integrations' },
        ]
    },
    {
        id: 2,
        type: "ENTERPRISE",
        title: 'Syndica Concierge',
        subTitle: 'For content and marketing teams who want to maximise quality and quantity of on brand content distributed across their audience channels with leading edge AI Automation and enterprise level support.',
        price: 0,
        buttonText: 'Contact us for custom pricing',
        isFeatured: false,
        featureTitle: 'Enterprise Plan includes everything in Syndica Business plus:',
        features: [
            { id: 1, title: 'Negotiated number of seats' },
            { id: 2, title: 'Negotiated number of Brand Voices' },
            { id: 3, title: 'Negotiated number of Content Generation Queries/Day' },
            { id: 4, title: 'Additional Content Formats' },
            { id: 5, title: 'Real Time Content Editor' },
            { id: 6, title: 'Negotiated number Content Distribution Integrations' },
            { id: 7, title: 'Priority Support' },
            { id: 8, title: 'Dedicated Customer Success Manager' }
        ]
    }
];
