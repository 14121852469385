import React from 'react';
import { createPortal } from 'react-dom';
import { Row, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faBars, faPlus } from '@fortawesome/free-solid-svg-icons';
import TagItem from './TagItem';
import StrictModeDroppable from './StrictModeDroppable';
import { Draggable } from 'react-beautiful-dnd';

const TagList = ({ item, index, currentDraggedItemId, handleTagChange, handleConditionChange, handleOperationChange, deleteItem, addTag }) => {
    return (
        <Draggable draggableId={item.id} index={index}>
            {(provided, snapshot) => {
                const shouldUsePortal = snapshot.isDragging;

                const child = (
                    <div ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={item.id == currentDraggedItemId ? 'dragging' : ''}>
                        <Card className="mb-3 kanban-item shadow-sm dark__bg-1100">
                            <Card.Body>
                                <Row>
                                    <Col className="d-flex justify-content-end">
                                        <div
                                            style={{
                                                width: '32px',
                                                height: '32px',
                                                borderRadius: '50%',
                                                backgroundColor: '#ff0e7',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                marginRight: '10px', // This ensures some space between the two icons
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => addTag(item.id)}
                                        >
                                            <FontAwesomeIcon icon={faPlus} />
                                        </div>
                                        <div
                                            style={{
                                                width: '32px',
                                                height: '32px',
                                                borderRadius: '50%',
                                                backgroundColor: '#ff0e7',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                marginRight: '10px', // This ensures some space between the two icons
                                                cursor: 'pointer'
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faBars} />
                                        </div>
                                        <div
                                            style={{
                                                width: '32px',
                                                height: '32px',
                                                borderRadius: '50%',
                                                backgroundColor: '#ff0e7',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => deleteItem(item.id)}
                                        >
                                            <FontAwesomeIcon icon={faTrash} />
                                        </div>
                                    </Col>
                                    <Col xs={12} md={12}>
                                        <StrictModeDroppable droppableId={item.id} index={index} key={item.id} type="DEFAULT">
                                            {provided => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.droppableProps}
                                                >
                                                    {
                                                        item.contents.length > 0
                                                            ? item.contents.map((tag, tagIndex) => (
                                                                <TagItem
                                                                    key={tag.id}
                                                                    index={tagIndex}
                                                                    item={tag}
                                                                    currentDraggedItemId={currentDraggedItemId}
                                                                    handleTagChange={handleTagChange}
                                                                    handleConditionChange={handleConditionChange}
                                                                    handleOperationChange={handleOperationChange}
                                                                    deleteItem={deleteItem} />
                                                            ))
                                                            : <h2 className='empty-tag-group'>Empty tag group</h2>
                                                    }
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </StrictModeDroppable>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </div>
                );

                return shouldUsePortal
                    ? createPortal(child, document.getElementById('dnd-portal'))
                    : child;
            }}
        </Draggable >
    );
}

export default TagList;