import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';
import api, { setAuthToken } from '../../api.js';

const ForgetPasswordForm = () => {
  // State
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (email) {
        api.post('/api/forgot-password', { email })
          .then(response => {
            const status = response.data.status;

            if (status) {
              toast.success(`An email is sent to ${email} with password reset link`, {
                theme: 'colored'
              });

              setTimeout(() => {
                // Redirect the user to the login page
                navigate('/login');
              }, 1500);
            }
            else {
              setError(response.data.message);
            }

          })
          .catch(error => {
            console.error('Registration failed:', error);
            setError(error.response.data.message);
          });
      }
    } catch (err) {
      setError('Registration failed.');
    }
  };

  return (
    <Form className="mt-4" onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Control
          placeholder={'Email address'}
          value={email}
          name="email"
          onChange={({ target }) => setEmail(target.value)}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Button className="w-100" type="submit" disabled={!email}>
          Send reset link
        </Button>
      </Form.Group>

      <Link className="fs--1 text-600" to="/login">
        I can't recover my account using this page
        <span className="d-inline-block ms-1"> &rarr;</span>
      </Link>
    </Form>
  );
};

ForgetPasswordForm.propTypes = {
  layout: PropTypes.string
};

ForgetPasswordForm.defaultProps = { layout: 'simple' };

export default ForgetPasswordForm;
