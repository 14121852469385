import React, { useState, useEffect, useRef } from 'react';
import { Form, Row, Col, Button, FloatingLabel, Modal } from 'react-bootstrap';
import FalconComponentCard from 'components/common/FalconComponentCard';
import api from '../../api.js';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const ToneOfVoiceQuickCreate = ({ modal, setModal, loadData }) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [toneOfVoice, setToneOfVoice] = useState({
        name: "",
        input: ""
    });

    const handleCloseNew = () => {
        setModal(false);
    }

    const handleCancel = (e) => {
        e.preventDefault(); // To prevent the form from submitting by default
        navigate('/toneOfVoices');
    }

    const handleSubmit = (e) => {
        console.log("Submit");
        e.preventDefault(); // To prevent the form from submitting by default

        // Check if any field is set to 'default'
        if (toneOfVoice.name === "") {
            toast.error("Please fill all required fields before submitting.", {
                theme: 'colored'
            });
            return;
        }

        api.post(`/api/tone-of-voice/quick-create`, toneOfVoice)
            .then(response => {
                const success = response.status;
                if (success == 201) {
                    toast.success(`Created new tone of voice successfully`, {
                        theme: 'colored'
                    });
                    handleCloseNew();
                    loadData();
                }
                else {
                    toast.error(`Failed to create tone of voice.`, {
                        theme: 'colored'
                    });
                }
            })
            .catch(error => {
                console.error('Error creating tone of voice in:', error);
            });
    };

    useEffect(() => {
        api.get(`/api/tone-of-voice/${id}`)
            .then(response => {
                const success = response.status;
                if (success == 200) {
                    setToneOfVoice(response.data);
                    toast.success(`Grabbed tone of voice successfully`, {
                        theme: 'colored'
                    });
                }
                else {
                    toast.error(`Failed to grab tone of voice.`, {
                        theme: 'colored'
                    });
                }
            })
            .catch(error => {
                console.error('Error grabbing tone of voice in:', error);
            });
    }, [id]);

    return (
        <Modal show={modal} onHide={handleCloseNew} dialogClassName="modal-90w" backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Create a new tone of voice</Modal.Title>
            </Modal.Header>
            <Modal.Body className='px-4'>
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col className="px-3" xs={12} md={12}>
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="eg. Car News"
                                    value={toneOfVoice.name || ''}
                                    onChange={e => setToneOfVoice({ ...toneOfVoice, name: e.target.value })}
                                />
                            </Form.Group>
                        </Col>
                        <Col className="px-3" xs={12} md={12}>
                            <Form.Group className="mb-3" controlId="input">
                                <Form.Label>Input text</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={10}
                                    type="textarea"
                                    placeholder="Text to analyse and generate a tone of voice."
                                    value={toneOfVoice.input || ''}
                                    onChange={e => setToneOfVoice({ ...toneOfVoice, input: e.target.value })}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleSubmit}>
                    Analyse
                </Button>
                <Button variant="secondary" onClick={handleCloseNew}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

ToneOfVoiceQuickCreate.propTypes = {
    modal: PropTypes.bool.isRequired,
    setModal: PropTypes.func.isRequired,
    loadData: PropTypes.func.isRequired
};

export default ToneOfVoiceQuickCreate;
