import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Button, Modal } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import api from '../../api.js';
import { toast } from 'react-toastify';
import Loading from 'components/utilities/Loading';

const ContentQuickCreate = () => {
    let {
        state: { open }
    } = useLocation();
    const navigate = useNavigate();

    const [show, setShow] = useState(open);

    const handleClose = () => {
        setShow(false);
        navigate(-1);
    };


    const [toneOfVoices, setToneOfVoices] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [content, setContent] = useState({
        toneOfVoiceId: 0,
        title: "",
        inputText: ""
    });

    const handleSubmit = (e) => {
        console.log("Submit");
        e.preventDefault(); // To prevent the form from submitting by default

        // Check if any field is set to 'default'
        if (content.name === "") {
            toast.error("Please fill all required fields before submitting.", {
                theme: 'colored'
            });
            return;
        }

        api.post(`/api/content/quick-create`, content)
            .then(response => {
                const success = response.status;
                if (success == 200) {
                    toast.success(`Created new content successfully`, {
                        theme: 'colored'
                    });
                    handleClose();
                    loadData();
                }
                else {
                    toast.error(`Failed to create content.`, {
                        theme: 'colored'
                    });
                }
            })
            .catch(error => {
                console.error('Error creating content in:', error);
            });
    };

    useEffect(() => {
        api.get('/api/tone-of-voice/analysed')
            .then(response => {
                const success = response.data.success;
                if (success) {
                    setToneOfVoices(response.data.data);
                    setLoading(false);
                }
                else {
                    toast.error(`Failed to grab queries.`, {
                        theme: 'colored'
                    });
                }
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    // Redirect to login page on 401 status
                    navigate('/login');
                }

                setLoading(false);
                toast.error(`Failed to grab tone of voices.`, {
                    theme: 'colored'
                });
                navigate('/');
            });
    }, []);

    return (
        <Modal id="quick-create-modal" show={show} onHide={handleClose} dialogClassName="modal-90w" backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>Create content from text</Modal.Title>
            </Modal.Header>
            <Modal.Body className='px-4'>
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col className="px-3" xs={12} md={12}>
                            <Loading loading={isLoading} isCenter={true} size="lg">
                                {
                                    <Form.Group className="mb-3 required" controlId="projectToneOfVoice">
                                        <Form.Label>What tone of voice would you like to use? *</Form.Label>
                                        <Form.Select
                                            aria-label="Please select a tone of voice..."
                                            required={true}
                                            value={content.toneOfVoiceId !== null && content.toneOfVoiceId !== undefined ? content.toneOfVoiceId : 'default'}
                                            onChange={e => setContent({ ...content, toneOfVoiceId: e.target.value })}
                                        >
                                            <option value="default">Please select a tone of voice</option>
                                            {toneOfVoices.map((toneOfVoice, index) => (
                                                <option value={toneOfVoice.id}>{toneOfVoice.name}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                }
                            </Loading>
                        </Col>
                        <Col className="px-3" xs={12} md={12}>
                            <Form.Group className="mb-3" controlId="projectName">
                                <Form.Label>Title *</Form.Label>
                                <Form.Control
                                    type="text"
                                    required={true}
                                    value={content.title || ''}
                                    onChange={e => setContent({ ...content, title: e.target.value })}
                                />
                            </Form.Group>
                        </Col>
                        <Col className="px-3" xs={12} md={12}>
                            <Form.Group className="mb-3" controlId="input">
                                <Form.Label>Source text</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={10}
                                    type="textarea"
                                    value={content.inputText || ''}
                                    onChange={e => setContent({ ...content, inputText: e.target.value })}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleSubmit}>
                    Create Content
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ContentQuickCreate;
