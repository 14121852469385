import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import AuthSimpleLayout from '../layouts/AuthSimpleLayout';
import MainLayout from '../layouts/MainLayout';
import ErrorLayout from '../layouts/ErrorLayout';

// ContentPug
import FetchNew from 'components/fetch/FetchNew';
import FetchEdit from 'components/fetch/FetchEdit';
import FetchList from 'components/fetch/FetchList';
import ConnectionNew from 'components/connection/ConnectionNew';
import ConnectionEdit from 'components/connection/ConnectionEdit';
import ConnectionList from 'components/connection/ConnectionList';
import ContentViewLayout from 'components/content/ContentViewLayout';
import ContentList from 'components/content/ContentList';
import ContentListAll from 'components/content/ContentListAll';
import ContentQuickCreate from 'components/content/ContentQuickCreate';
import SourceEdit from 'components/source/SourceEdit';
import SourceList from 'components/source/SourceList';
import SourceGroupNew from 'components/source-groups/SourceGroupNew';
import SourceGroupEdit from 'components/source-groups/SourceGroupEdit';
import SourceGroupList from 'components/source-groups/SourceGroupList';
import RawContentList from 'components/raw-content/RawContentList';
import ToneOfVoiceNew from 'components/tone-of-voice/ToneOfVoiceNew';
import ToneOfVoiceEdit from 'components/tone-of-voice/ToneOfVoiceEdit';
import ToneOfVoiceList from 'components/tone-of-voice/ToneOfVoiceList';
import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';
import Login from 'components/auth/Login';
import Logout from 'components/auth/Logout';
import Registration from 'components/auth/Registration';
import ForgetPassword from 'components/auth/ForgetPassword';
import PasswordReset from 'components/auth/PasswordReset';
import ConfirmMail from 'components/auth/ConfirmMail';

const FalconRoutes = () => {
  return (
    <Routes>
      <Route element={<MainLayout />}>
        {/*- ------------- Lazy Pug ---------------------------  */}
        <Route path="/" element={<PrivateRoute />}>
          <Route index element={<FetchList />} />
          <Route path="content" element={<ContentListAll />} />
          <Route path="content/:id" element={<ContentViewLayout />} />
          <Route path="quick-create" element={<ContentQuickCreate />} />
          <Route path="fetches/new" element={<FetchNew />} />
          <Route path="fetches/:id/edit" element={<FetchEdit />} />
          <Route path="fetches/:id/content" element={<ContentList />} />
          <Route path="connections" element={<ConnectionList />} />
          <Route path="connections/new" element={<ConnectionNew />} />
          <Route path="connections/:id/edit" element={<ConnectionEdit />} />
          <Route path="fetches" element={<FetchList />} />
          <Route path="sources" element={<SourceList />} />
          <Route path="sources/:id/edit" element={<SourceEdit />} />
          <Route path="sources/:id/raw-content" element={<RawContentList />} />
          <Route path="source-groups" element={<SourceGroupList />} />
          <Route path="source-groups/new" element={<SourceGroupNew />} />
          <Route path="source-groups/:id/edit" element={<SourceGroupEdit />} />
          <Route path="tone-of-voice/new" element={<ToneOfVoiceNew />} />
          <Route path="tone-of-voice/:id/edit" element={<ToneOfVoiceEdit />} />
          <Route path="tone-of-voice" element={<ToneOfVoiceList />} />
          {/* ... You can continue with more nested routes if needed ... */}
        </Route>
        {/* //--- MainLayout end  */}
      </Route>
      {/*- ------------- Authentication ---------------------------  */}

      {/*- ------------- simple ---------------------------  */}
      <Route element={<AuthSimpleLayout />}>
        <Route path="/login" element={<Login />} />
        <Route
          path="/register"
          element={<Registration />}
        />
        <Route path="/logout" element={<Logout />} />
        <Route
          path="/forgot-password"
          element={<ForgetPassword />}
        />
        <Route
          path="/reset-password"
          element={<PasswordReset />}
        />
        <Route
          path="/confirm-mail"
          element={<ConfirmMail />}
        />
      </Route>
      <Route element={<ErrorLayout />}>
        <Route path="errors/404" element={<Error404 />} />
        <Route path="errors/500" element={<Error500 />} />
      </Route>
      {/* <Navigate to="/errors/404" /> */}
      <Route path="*" element={<Navigate to="/errors/404" replace />} />
    </Routes>
  );
};

export default FalconRoutes;
