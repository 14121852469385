import IconButton from 'components/common/IconButton';
import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';
import { Col, Form, Row, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import SourceNewModal from './SourceNewModal';

const SourceListsTableHeader = ({ go }) => {
  const navigate = useNavigate();

  const [modalNew, setShowNew] = useState(false);

  const handleNewSource = () => {
    setShowNew(true);
  }

  return (
    <Row className="flex-between-center">
      <SourceNewModal modal={modalNew} setModal={setShowNew} />
      <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
        <h4 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Sources</h4>
      </Col>
      <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
        <div id="orders-actions">
          <IconButton
            variant="falcon-default"
            size="sm"
            icon="plus"
            transform="shrink-3"
            onClick={() => handleNewSource()}
          >
            <span className="d-none d-sm-inline-block ms-1">New</span>
          </IconButton>
        </div>
      </Col>

    </Row>
  );
};

SourceListsTableHeader.propTypes = {
  setModalShow: PropTypes.func
};

export default SourceListsTableHeader;
