import { Card, Dropdown, Modal, Button, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Moment from 'react-moment';

const ContentCard = ({ rawContent, showGenerateModal }) => {
    return (
        <Card className="h-100">
            {rawContent.imageUrl && <Card.Img src={rawContent.imageUrl} variant='top' />}
            <Card.Body>
                <Card.Title as='h5'>
                    {rawContent.title}
                </Card.Title>
                <Card.Text>
                    {rawContent.contentPlain?.substring(0, 200) || 'No content available'}...
                </Card.Text>
                <hr style={{ borderStyle: 'dashed' }} />
                <Row>
                    <Col xs={12} md={6} lg={4}>
                        <div style={{ borderRight: 'solid 1px #ced4da' }}>
                            <h6 className='card-meta-header'>SOURCES:</h6>
                            <h6 className='card-meta-info'>{rawContent.similarArticles}</h6>
                        </div>
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <div style={{ borderRight: 'solid 1px #ced4da' }}>
                            <h6 className='card-meta-header'>RESULT CREATED:</h6>
                            <h6 className='card-meta-info'><Moment fromNow>{rawContent.createdAt}</Moment></h6>
                        </div>
                    </Col>
                    <Col xs={12} md={6} lg={4}>
                        <div>
                            <h6 className='card-meta-header'>CONTENT PUBLISHED:</h6>
                            <h6 className='card-meta-info'><Moment fromNow>{rawContent.publishedAt}</Moment></h6>
                        </div>
                    </Col>
                </Row>
            </Card.Body>
            <div className="p-3" style={{ backgroundColor: '#f3f2f7', borderBottomLeftRadius: '6px', borderBottomRightRadius: '6px' }}>
                <Row>
                    <Col className="d-flex justify-content-center">
                        <Button className='mx-2' variant="outline-primary" size="sm" href={rawContent.canonicalUrl} target="_blank" rel="noopener noreferrer">
                            VIEW FULL ARTICLE
                        </Button>
                        <Button className='mx-2' variant="outline-primary" size="sm" onClick={() => showGenerateModal(rawContent)}>
                            GENERATE FROM ARTICLE
                        </Button>
                    </Col>

                </Row>
            </div>


        </Card >
    );
};

ContentCard.propTypes = {
    showGenerateModal: PropTypes.func.isRequired,
};

export default ContentCard;
