import axios from 'axios';
import React, { useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { QueryContext } from './context/Context';
var baseURL = "http://localhost:8080";

console.log(process.env.REACT_APP_ENV);
// Staging
if (process.env.REACT_APP_ENV === 'staging') {
  baseURL = "https://api.staging.syndica.ai";
}


// Production
if (process.env.REACT_APP_ENV === 'production') {
  baseURL = "https://app.syndica.ai";
}

const api = axios.create({
  baseURL: baseURL,
});



export const setAuthToken = (token) => {
  if (token) {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete api.defaults.headers.common['Authorization'];
  }
};

// Get the token from local storage
const token = localStorage.getItem('token');

if (token) {
  // If the token exists, set it in the default headers
  setAuthToken(token);
} else {
  // If not, ensure the Authorization header is removed
  delete api.defaults.headers.common['Authorization'];
}

export default api;

export const getFetches = async () => {
  const response = await api.get(`/api/fetches`);
  return response;
};

export const getFetch = (id) => {

  const navigate = useNavigate();

  api.get(`/api/fetches/${id}`)
    .then(response => {
      const success = response.status;
      if (success == 200) {
        toast.success(`Grabbed fetch successfully`, {
          theme: 'colored'
        });

        if (response.data.tagFilter) {
          const payload = {
            ...fetchData,
            tagFilter: JSON.parse(response.data.tagFilter)
          };
          return payload;
        } else {
          return response.data;
        }
      }
      else {
        toast.error(`Failed to grab fetch.`, {
          theme: 'colored'
        });
      }
    })
    .catch(error => {
      console.error('Error grabbing fetch in:', error);
      if (error.response && error.response.status === 401) {
        // Redirect to login page on 401 status

        navigate('/login');
      }
    });
}