import React, { useState, useEffect, useRef } from 'react';
import { Form, Row, Col, Button, Card } from 'react-bootstrap';
import FalconComponentCard from 'components/common/FalconComponentCard';
import api from '../../api.js';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Loading from 'components/utilities/Loading';
import Select, { components } from 'react-select';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
const ActionHandlerContext = React.createContext();
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import SoftBadge from 'components/common/SoftBadge';

const columns = [
    {
        accessor: 'name',
        Header: 'Name',
        headerProps: { className: 'pe-1' },
        cellProps: {
            className: 'py-2'
        },
        Cell: rowData => {
            const { label, sourceViewType } = rowData.row.original;
            return (
                <Flex alignItems="center">
                    <div className="flex-1">
                        <h5 className="mb-0 fs--1"><SoftBadge bg="info" className="fw-medium text-decoration-none me-2">{sourceViewType}</SoftBadge> {label}</h5>
                    </div>
                </Flex>
            );
        }
    },
    {
        accessor: 'none',
        Header: '',
        disableSortBy: true,
        cellProps: {
            className: 'text-end'
        },
        Cell: rowData => {
            const { handleRemoveItem } = React.useContext(ActionHandlerContext)
            return (
                <>
                    <IconButton
                        variant="danger"
                        size="sm"
                        icon="trash"
                        transform="shrink-3"
                        className="mx-1"
                        onClick={() => {
                            handleRemoveItem(rowData.row.original);
                        }}
                    >
                        <span className="d-none d-sm-inline-block ms-1">Remove</span>
                    </IconButton>
                </>
            );
        }
    }
];

const SourceSelector = ({ items, selectedItems, setSelectedItems }) => {
    const [availableItems, setAvailableItems] = useState(items.filter(item => !selectedItems.find(selectedItem => selectedItem.value === item.value)));
    const [selected, setSelected] = useState(null);

    const handleSelectChange = (selectedOption) => {
        // Check if the item is already in the list
        if (!selectedItems.find(item => item.value === selectedOption.value)) {
            setSelectedItems([...selectedItems, selectedOption]);
            setAvailableItems(availableItems.filter(item => item.value !== selectedOption.value));
            setSelected(null);
        }
    };

    const handleRemoveItem = (itemToRemove) => {
        setSelectedItems(selectedItems.filter(item => item.value !== itemToRemove.value));
        setAvailableItems([...availableItems, itemToRemove]);
    };

    const { Option } = components;
    const IconOption = props => (
        <Option {...props}>
            <SoftBadge bg="info" className="fw-medium text-decoration-none me-2">{props.data.sourceViewType}</SoftBadge>
            {props.data.label}
        </Option>
    );

    return (
        <>
            <Row className='m-3 g-3'>
                <Col className="px-3" xs={12} md={6}>
                    <h4>Sources</h4>
                    <Select
                        className='pb-3'
                        value={selected || ''}
                        options={availableItems}
                        components={{ Option: IconOption }}
                        onChange={handleSelectChange}
                        placeholder="Select source"
                    />
                </Col>
            </Row>

            <Row className='m-3 g-3'>
                <Col className="px-3 pb-3" xs={12} md={6}>
                    <ActionHandlerContext.Provider value={{ handleRemoveItem }}>
                        <AdvanceTableWrapper
                            columns={columns}
                            data={selectedItems}
                            pagination
                            perPage={10}
                            className='pb-3'
                        >
                            <AdvanceTable
                                table
                                headerClassName="bg-200 text-900 text-nowrap align-middle"
                                rowClassName="align-middle white-space-nowrap"
                                tableProps={{
                                    bordered: true,
                                    striped: true,
                                    className: 'fs--1 mb-0'
                                }}
                            />

                            <AdvanceTablePagination table />
                        </AdvanceTableWrapper>
                    </ActionHandlerContext.Provider>
                </Col>
            </Row>
        </>
    );
};
export default SourceSelector;