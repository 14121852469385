import React, { useState, useEffect } from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import ContentViewBreadcrumbs from './ContentViewBreadcrumbs';
import ContentViewAside from './ContentViewAside';
import ContentViewContent from './ContentViewContent';
import ContentViewHeaderImage from './ContentViewHeaderImage';
import Loading from 'components/utilities/Loading';
import api from '../../api.js';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import ContentViewProvider from './ContentViewProvider';

const ContentViewLayout = () => {
    const [loading, setLoading] = useState(false);
    const [content, setContent] = useState({});
    const { id } = useParams();
    useEffect(() => {
        setLoading(true);
        api.get(`/api/content/${id}`)
            .then(response => {
                setLoading(false);
                setContent(response.data);
            })
            .catch(error => {
                setLoading(false);
                toast.error(`Failed to grab content.`, {
                    theme: 'colored'
                });
            });
    }, [id]);
    return (
        <>
            <Loading loading={loading} isCenter={true} size="lg">
                <ContentViewProvider>
                    <Row className="g-3">
                        <Col lg={9}>
                            <ContentViewBreadcrumbs content={content} />
                            <ContentViewContent content={content} />
                        </Col>
                        <Col lg={3}>
                            <ContentViewHeaderImage content={content} />
                            <ContentViewAside content={content} />
                        </Col>
                    </Row>
                </ContentViewProvider>
            </Loading>

        </>
    );
};

export default ContentViewLayout;
