import React, { useState, useRef, useEffect } from 'react';
import { Card, Dropdown, Form, Modal, Button, Row, Col } from 'react-bootstrap';
import api from '../../api.js';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import ContentCard from './ContentCard.js';
import Loading from 'components/utilities/Loading';

const RawContentList = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [toneOfVoices, setToneOfVoices] = useState([]);
    const [activeToneOfVoiceId, setActiveToneOfVoiceId] = useState(0);
    const [activeRawContentId, setActiveRawContentId] = useState(0);
    const [isLoading, setLoading] = useState(true);

    const [rawContents, setRawContents] = useState([]);

    const hasRun = useRef(false);
    useEffect(() => {
        if (!hasRun.current) {
            api.get(`/api/sources/${id}/raw-content`)
                .then(response => {
                    const success = response.data.success;
                    if (success) {
                        setRawContents(response.data.data);
                        toast.success(`Grabbed raw content successfully`, {
                            theme: 'colored'
                        });
                    }
                    else {
                        toast.error(`Failed to grab raw content.`, {
                            theme: 'colored'
                        });
                    }
                })
                .catch(error => {
                    console.error('Error grabbing raw content in:', error);
                });


            api.get('/api/tone-of-voice/analysed')
                .then(response => {
                    const success = response.data.success;
                    if (success) {
                        setToneOfVoices(response.data.data);
                        setLoading(false);
                    }
                })
                .catch(error => {
                    if (error.response && error.response.status === 401) {
                        // Redirect to login page on 401 status
                        navigate('/login');
                    }

                    setLoading(false);
                    toast.error(`Failed to grab tone of voices.`, {
                        theme: 'colored'
                    });
                    navigate('/');
                });

            hasRun.current = true;
        }
    }, [id]);

    const handleCloseNew = () => {
        setShowModal(false);
        setActiveToneOfVoiceId(0);
        setActiveRawContentId(0);
    }

    const showGenerateModal = (rawContent) => {
        setShowModal(true);
        setActiveRawContentId(rawContent.id);
    }

    const generateContent = () => {
        let data = {
            rawContentId: activeRawContentId,
            toneOfVoiceId: activeToneOfVoiceId
        };

        api.post(`/api/raw-contents/generate-content`, data)
            .then(response => {
                const success = response.data.success;
                if (success) {
                    toast.success(`Generating content, give it a few minutes to generate.`, {
                        theme: 'colored'
                    });
                }
                else {
                    toast.error(`Failed to generate content.`, {
                        theme: 'colored'
                    });
                }
                setShowModal(false);
            })
            .catch(error => {
                console.error('Error generating content:', error);
            })
    }

    return (
        <>
            <Row>
                <Modal show={showModal} onHide={handleCloseNew} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>Select tone of voice</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='px-4'>
                        <Form onSubmit={generateContent}>
                            <Row>
                                <Col className="px-3" xs={12} md={12}>
                                    <Loading loading={isLoading} isCenter={true} size="lg">
                                        {
                                            <Form.Group className="mb-3 required" controlId="projectToneOfVoice">
                                                <Form.Label>What tone of voice would you like to use? *</Form.Label>
                                                <Form.Select
                                                    aria-label="Please select a tone of voice..."
                                                    required={true}
                                                    value={activeToneOfVoiceId !== null && activeToneOfVoiceId !== undefined ? activeToneOfVoiceId : '0'}
                                                    onChange={e => setActiveToneOfVoiceId(e.target.value)}
                                                >
                                                    <option value="default">Please select a tone of voice</option>
                                                    {toneOfVoices.map((toneOfVoice, index) => (
                                                        <option value={toneOfVoice.id}>{toneOfVoice.name}</option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        }
                                    </Loading>
                                </Col>
                            </Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={generateContent}>
                            Create Content
                        </Button>
                        <Button variant="secondary" onClick={handleCloseNew}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                {rawContents.map((rawContent, index) => (
                    <Col xs={12} md={6} lg={4} key={index} className="mb-4">
                        <ContentCard rawContent={rawContent} showGenerateModal={showGenerateModal} />
                    </Col>
                ))}
            </Row>
        </>
    );
};

export default RawContentList;
