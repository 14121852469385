import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ContentViewContext } from 'context/Context';
import api from 'api';
import 'assets/vendor/redactor/redactor.min.css';
import Redactor from 'assets/vendor/redactor/redactor.min.js';

const ContentViewProvider = ({ children }) => {
    const [loadingEdit, setLoadingEdit] = useState(false);
    const [editingId, setEditingId] = useState(0);

    const handleEdit = (contentId) => {
        setLoadingEdit(false);
        setEditingId(contentId);
        Redactor(`#content-${contentId}`);
    };

    const handleSubmit = (contentId) => {
        const updatedContent = Redactor(`#content-${contentId}`, 'source.getCode');
        const updateData = {
            "contentPlain": updatedContent
        };
        setLoadingEdit(true);

        api.put(`/api/content/${contentId}`, updateData)
            .then(response => {
                const success = response.status;
                setLoadingEdit(false);
                setEditingId(0);
                if (success == 200) {
                    toast.success(`Updated content successfully`, {
                        theme: 'colored'
                    });
                    navigate('/fetches');
                }
                else {
                    toast.error(`Failed to update content.`, {
                        theme: 'colored'
                    });
                }
            })
            .catch(error => {
                console.error('Error updating content in:', error);
            })
            .finally(() => {
                Redactor(`#content-${contentId}`, 'destroy');
            });
    };

    const handleCancel = (contentId) => {
        setLoadingEdit(false);
        setEditingId(0);
        Redactor(`#content-${contentId}`, 'destroy');
    };

    return (
        <ContentViewContext.Provider value={{ editingId, loadingEdit, handleEdit, handleSubmit, handleCancel }}>
            {children}
        </ContentViewContext.Provider>
    );
};

ContentViewProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export default ContentViewProvider;
