import React, { useState, useEffect, useRef } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import FalconComponentCard from 'components/common/FalconComponentCard';
import api from '../../api.js';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import TagInput from './TagInput.js';
import TagSelector from './TagSelector.js';
import Loading from 'components/utilities/Loading';

const FetchNew = () => {
    const navigate = useNavigate();
    const hasRun = useRef(false);
    const [toneOfVoices, setToneOfVoices] = useState([]);
    const [sourceGroups, setSourceGroups] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [sourceOptions, setSourceOptions] = useState([]);
    const [fetchData, setFetchData] = useState({
        tagFilter: []
    });


    const setTagFilter = (tagFilter) => {
        setFetchData(prevData => ({
            ...prevData,
            tagFilter: tagFilter
        }));
    };

    const handleCancel = (e) => {
        e.preventDefault(); // To prevent the form from submitting by default
        navigate('/fetches');
    }

    const handleSubmit = (e) => {
        e.preventDefault(); // To prevent the form from submitting by default

        // Check if any field is set to 'default'
        if (fetchData.sourceId === "default" ||
            fetchData.desiredType === "default" ||
            fetchData.desiredGender === "default" ||
            fetchData.desiredAge === "default" ||
            fetchData.toneOfVoiceId === "default") {
            toast.error("Please select all required fields before submitting.", {
                theme: 'colored'
            });
            return;
        }

        const payload = {
            ...fetchData,
            tagFilter: JSON.stringify(fetchData.tagFilter)
        };

        api.post(`/api/fetches`, payload)
            .then(response => {
                const success = response.status;
                if (success == 201) {
                    toast.success(`Created new fetch successfully`, {
                        theme: 'colored'
                    });
                    navigate('/fetches');
                }
                else {
                    toast.error(`Failed to create fetch.`, {
                        theme: 'colored'
                    });
                }
            })
            .catch(error => {
                console.error('Error creating fetch in:', error);
            })

        // If everything is fine, proceed with your submission logic here
        console.log("Submit the form");
    };

    const handleTagsChange = (tags) => {
        setFetchData({ ...fetchData, tags: tags });
    };

    useEffect(() => {
        setLoading(true);
        const fetchSourceGroups = api.get('/api/source-groups')
            .then(response => {
                const success = response.data.success;
                if (success) {
                    setSourceGroups(response.data.data);
                }
                else {
                    toast.error(`Failed to grab queries.`, {
                        theme: 'colored'
                    });
                }
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    // Redirect to login page on 401 status
                    navigate('/login');
                }

                toast.error(`Failed to grab tone of voices.`, {
                    theme: 'colored'
                });
                navigate('/');
            });

        const fetchToneOfVoices = api.get('/api/tone-of-voice/analysed')
            .then(response => {
                const success = response.data.success;
                if (success) {
                    setToneOfVoices(response.data.data);
                }
                else {
                    toast.error(`Failed to grab queries.`, {
                        theme: 'colored'
                    });
                }
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    // Redirect to login page on 401 status
                    navigate('/login');
                }

                toast.error(`Failed to grab tone of voices.`, {
                    theme: 'colored'
                });
                navigate('/');
            });

        Promise.all([fetchSourceGroups, fetchToneOfVoices])
            .finally(() => {
                setLoading(false);
            });
    }, []);

    return (
        <FalconComponentCard>
            <FalconComponentCard.Header title="New Query" light={false} noPreview={true}>
                <p className="mt-2 mb-0">
                    Syndica will continuously query and create content.
                </p>
            </FalconComponentCard.Header>
            <FalconComponentCard.Body>
                <Loading loading={isLoading} isCenter={true} size="lg">
                    {
                        toneOfVoices.length > 0 ? (
                            <>
                                <Form onSubmit={handleSubmit}>
                                    <Row className='m-3 g-3'>
                                        <Col className="px-3" xs={12} md={6}>
                                            <h4>General</h4>
                                            <Form.Group className="mb-3" controlId="projectName">
                                                <Form.Label>Name *</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    required={true}
                                                    placeholder="eg. Car News"
                                                    value={fetchData.name || ''}
                                                    onChange={e => setFetchData({ ...fetchData, name: e.target.value })}
                                                />
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="sourceType">
                                                <Form.Label>Source Category *</Form.Label>
                                                <Form.Select
                                                    aria-label="eg. All, Sports"
                                                    value={fetchData.sourceCategory || 'ALL'}
                                                    onChange={e => setFetchData({ ...fetchData, sourceCategory: e.target.value })}
                                                >
                                                    <option value="ALL">All</option>
                                                    <option value="AGRICULTURE">Agriculture</option>
                                                    <option value="AUTOMOTIVE">Automotive</option>
                                                    <option value="BUSINESS">Business</option>
                                                    <option value="CULTURE">Culture</option>
                                                    <option value="ECONOMY">Economy</option>
                                                    <option value="EDUCATION">Education</option>
                                                    <option value="ENERGY">Energy</option>
                                                    <option value="ENTERTAINMENT">Entertainment</option>
                                                    <option value="ENVIRONMENT">Environment</option>
                                                    <option value="HEALTH">Health</option>
                                                    <option value="LEGAL">Legal</option>
                                                    <option value="LIFESTYLE">Lifestyle</option>
                                                    <option value="OPINION">Opinion</option>
                                                    <option value="POLITICS">Politics</option>
                                                    <option value="REAL_ESTATE">Real Estate</option>
                                                    <option value="SCIENCE">Science</option>
                                                    <option value="SPORTS">Sports</option>
                                                    <option value="TECHNOLOGY">Technology</option>
                                                    <option value="TECHNOLOGY_AI">Technology Ai</option>
                                                    <option value="UNSPECIFIED">Unspecified</option>
                                                    <option value="WORLD_NEWS">World News</option>

                                                </Form.Select>
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="projectType">
                                                <Form.Label>Source Type *</Form.Label>
                                                <Form.Select
                                                    aria-label="eg. All, Sports"
                                                    value={fetchData.sourceType || 'ALL'}
                                                    onChange={e => setFetchData({ ...fetchData, sourceType: e.target.value })}
                                                >
                                                    <option value="ALL">All</option>
                                                    <option value="PRIVATE_ONLY">Private Only</option>
                                                    <option value="PUBLIC_ONLY">Public Only</option>
                                                </Form.Select>
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="projectType">
                                                <Form.Label>Result Type *</Form.Label>
                                                <Form.Select
                                                    aria-label="eg. Short Twitter Post"
                                                    required={true}
                                                    value={fetchData.desiredType || 'default'}
                                                    onChange={e => setFetchData({ ...fetchData, desiredType: e.target.value })}
                                                >
                                                    <option value="default" disabled>eg. Short Twitter Post</option>
                                                    <option value="Short Tweet">Short Tweet</option>
                                                    <option value="Facebook Post">Facebook Post</option>
                                                    <option value="Instagram Post">Instagram Post</option>
                                                    <option value="LinkedIn Post">LinkedIn Post</option>
                                                    <option value="Short Blog Post">Short Blog Post</option>
                                                    <option value="Long Blog Post">Long Blog Post</option>
                                                    <option value="A script for Instagram/TikTok reels">A script for Instagram/TikTok reels</option>
                                                </Form.Select>
                                            </Form.Group>
                                            <Form.Group className="mb-3 required" controlId="projectToneOfVoice">
                                                <Form.Label>Filter by Source Group?</Form.Label>
                                                <Form.Select
                                                    aria-label="Please select a source group..."
                                                    required={true}
                                                    value={fetchData.sourceGroupId !== null && fetchData.sourceGroupId !== undefined ? fetchData.sourceGroupId : 'default'}
                                                    onChange={e => setFetchData({ ...fetchData, sourceGroupId: e.target.value })}
                                                >
                                                    <option value="default">Please select a source group</option>
                                                    {sourceGroups.map((sourceGroup, index) => (
                                                        <option value={sourceGroup.id}>{sourceGroup.name}</option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col className="px-3" xs={12} md={6}>
                                            <h4>Tone of voice</h4>
                                            <Form.Group className="mb-3 required" controlId="projectToneOfVoice">
                                                <Form.Label>What tone of voice would you like to use? *</Form.Label>
                                                <Form.Select
                                                    aria-label="Please select a tone of voice..."
                                                    required={true}
                                                    value={fetchData.toneOfVoiceId !== null && fetchData.toneOfVoiceId !== undefined ? fetchData.toneOfVoiceId : 'default'}
                                                    onChange={e => setFetchData({ ...fetchData, toneOfVoiceId: e.target.value })}
                                                >
                                                    <option value="default">Please select a tone of voice</option>
                                                    {toneOfVoices.map((toneOfVoice, index) => (
                                                        <option value={toneOfVoice.id}>{toneOfVoice.name}</option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <TagSelector tagFilter={fetchData.tagFilter} setTagFilter={setTagFilter} />
                                        </Col>
                                    </Row>

                                    <Button variant="secondary" onClick={handleCancel} className='mx-1'>
                                        Cancel
                                    </Button>
                                    <Button variant="primary" type="submit" className='mx-1'>
                                        Create Query
                                    </Button>
                                </Form>
                            </>
                        ) : (
                            <p className='text-center'>You must first create a tone of voice</p>
                        )
                    }
                </Loading>
            </FalconComponentCard.Body>
        </FalconComponentCard >
    );
};

export default FetchNew;
