import React from 'react';
import eventBanner from 'assets/img/generic/13.jpg';
import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
const ContentViewHeaderImage = ({ content }) => {

    return (
        <Card className="p-0 mb-3">
            {content && content.imageUrl && (
                <img className="card-img-top" src={content.imageUrl} alt="" />
            )}
        </Card>
    );
};

ContentViewHeaderImage.propTypes = {
    content: PropTypes.object
};

export default ContentViewHeaderImage;
