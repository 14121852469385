import React, { useState, useEffect } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import api from '../../api.js';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import ContentCardLayout from './ContentLayout.js';
import { useBreakpoints } from 'hooks/useBreakpoints';
import useBulkSelect from 'hooks/useBulkSelect';
import ContentListHeader from './ContentListHeader.js';
import Loading from 'components/utilities/Loading.js';


const ContentList = (props) => {
    const [loading, setLoading] = useState(false);
    const { breakpoints } = useBreakpoints();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [fetches, setFetches] = useState([]);
    const [filteredFetches, setFilteredFetches] = useState([]);

    const handleContentSearch = text => {
        setFilteredFetches(
            fetches.filter(
                content => content.title.toLowerCase().includes(text.toLowerCase())
            )
        );
    };

    const handleQuickCreate = () => {
        setQuickCreateModalShow(true);
    };

    const { id } = useParams();
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [activeFetch, setActiveFetch] = useState(null);

    useEffect(() => {
        setLoading(true);
        api.get(`/api/fetches/${id}/content`)
            .then(response => {
                const success = response.data.success;
                if (success) {
                    setLoading(false);
                    setFetches(response.data.data);
                    setFilteredFetches(response.data.data);
                    toast.success(`Grabbed content successfully`, {
                        theme: 'colored'
                    });
                }
                else {
                    setLoading(false);
                    toast.error(`Failed to grab content.`, {
                        theme: 'colored'
                    });
                }
            })
            .catch(error => {
                setLoading(false);
                console.error('Error grabbing content in:', error);
            });
    }, [id]);


    // Derive ticketIds from the tickets state
    const fetchIds = fetches.map(fetch => fetch.id);

    // Call your useBulkSelect hook
    const { selectedItems, isSelectedItem, toggleSelectedItem } =
        useBulkSelect(fetchIds);

    const handleRemoveItem = (itemId) => {
        const newData = filteredFetches.filter(content => content.id !== itemId);
        setFilteredFetches(newData);
    };

    return (
        <>
            <Row className="gx-3">
                <Col xxl={12} xl={12}>
                    <Card className="mb-3">
                        <Card.Header>
                            <ContentListHeader
                                handleShow={handleShow}
                                selectedItems={selectedItems}
                                handleQuickCreate={handleQuickCreate}
                                handleContentSearch={handleContentSearch}
                            />
                        </Card.Header>
                        <Card.Body className="bg-light">
                            <Loading loading={loading} isCenter={true} size='lg'>
                                <ContentCardLayout
                                    data={filteredFetches}
                                    isSelectedItem={isSelectedItem}
                                    toggleSelectedItem={toggleSelectedItem}
                                    handleRemoveItem={handleRemoveItem}
                                />
                            </Loading>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default ContentList;