export const appRoutes = {
  label: 'app',
  children: [
    {
      name: 'Tone of Voice',
      icon: 'microphone',
      to: '/tone-of-voice',
      active: true
    },
    {
      name: 'Rewrite Content',
      icon: 'pencil-alt',
      to: '/quick-create',
      active: true
    },
    {
      name: 'Query',
      icon: 'search',
      to: '/',
      active: true
    },
    {
      name: 'Sources',
      icon: 'record-vinyl',
      to: '/sources',
      active: true
    },
    {
      name: 'Source Groups',
      icon: 'layer-group',
      to: '/source-groups',
      active: true
    },
    {
      name: 'Result',
      icon: 'newspaper',
      to: '/content',
      active: true
    },
    {
      name: 'Connections',
      icon: 'globe',
      to: '/connections',
      active: true
    },
    {
      name: 'Logout',
      icon: 'sign-out-alt',
      to: '/logout',
      active: true
    }
  ]
};

export default [
  appRoutes
];
