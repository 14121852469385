import React, { useState } from 'react';
import { Form, Modal, Button, FloatingLabel } from 'react-bootstrap';
import Loading from 'components/utilities/Loading';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import api from 'api.js';

const ContentSendModal = ({ showSendModal, setShowSendModal }) => {

    const [loadingConnections, setLoadingConnections] = useState(false);
    const [loadingSend, setLoadingSend] = useState(false);

    const [connectionId, setConnectionId] = useState(null);
    const [connectionList, setConnectionList] = useState([]);

    const { id } = useParams();

    const handleClose = () => {
        setShowSendModal(false);
    }

    const handleShow = () => {
        setLoadingConnections(true);
        api.get(`/api/connections`)
            .then(response => {
                if (response.status === 200) {
                    setConnectionList(response.data.data);
                    setShowSendModal(true);
                }
            })
            .catch(error => {
                console.error("There was an error sending the content:", error);

            })
            .finally(() => {
                setLoadingConnections(false);
            });
    }

    const handleSendClick = () => {
        setLoadingSend(true);
        const sendNuggetRequest = {
            "connectionId": connectionId,
            "contentId": id
        }

        // Check if any field is set to 'default'
        if (connectionId === "default" || id === null) {
            toast.error("Please select all required fields before submitting.", {
                theme: 'colored'
            });
            return;
        }

        api.post(`/api/content/${id}/send`, sendNuggetRequest)
            .then(response => {
                if (response.status === 200) {
                    toast.success(`Result sent succesfully.`, {
                        theme: 'colored'
                    });
                }
                setLoadingSend(false);
                setShowSendModal(false);
            })
            .catch(error => {
                console.error("There was an error sending the content:", error);
                setLoadingSend(false);
                setShowSendModal(false);
            });
    };

    return (
        <>
            <Modal show={showSendModal} onShow={handleShow} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Send Result</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        connectionList.length > 0 ? (
                            <>
                                <p>Please select where you'd like to send your nugget to.</p>
                                <FloatingLabel controlId="floatingSelect" label="Provider">
                                    <Form.Select value={connectionId} onChange={e => setConnectionId(e.target.value)}>
                                        <option value="default">Please select connection</option>
                                        {connectionList.map((connection, index) => (
                                            <option value={connection.id}>{connection.name}</option>
                                        ))}
                                    </Form.Select>
                                </FloatingLabel>
                            </>
                        ) : (
                            <p className='text-center'>You must first create connections</p>
                        )
                    }

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" disabled={loadingSend} onClick={handleSendClick}>
                        Send <Loading loading={loadingSend} />
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    );
};

ContentSendModal.propTypes = {
    showSendModal: PropTypes.bool.isRequired,
    setShowSendModal: PropTypes.func.isRequired
};

export default ContentSendModal;
