import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Loading from 'components/utilities/Loading';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import api from 'api.js';
import { useNavigate } from 'react-router-dom';

const ContentDeleteModal = ({ showDeleteModal, setShowDeleteModal }) => {
    const [loadingDelete, setLoadingDelete] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();

    const handleClose = () => {
        setShowDeleteModal(false);
    }

    const handleDeleteClick = () => {

        setLoadingDelete(true);
        api.delete(`/api/content/${id}`)
            .then(response => {
                if (response.status === 204) {
                    console.log("Content deleted successfully");
                    toast.success(`Deleted content succesfully.`, {
                        theme: 'colored'
                    });

                    navigate('/content');
                }
            })
            .catch(error => {
                console.error("There was an error deleting the content:", error);
            })
            .finally(() => {
                setLoadingDelete(false);
            });
    };

    return (
        <>
            <Modal show={showDeleteModal} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Are you sure you want to delete this content?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Once you delete this content, it cannot be recovered.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" disabled={loadingDelete} onClick={handleDeleteClick}>
                        Delete <Loading loading={loadingDelete} />
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    );
};

ContentDeleteModal.propTypes = {
    showDeleteModal: PropTypes.bool.isRequired,
    setShowDeleteModal: PropTypes.func.isRequired
};

export default ContentDeleteModal;
