
import React, { useState, useRef, useEffect, useContext } from 'react';
import { Card, Dropdown, Modal, Button } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import SourceGroupListTableHeader from './SourceGroupListTableHeader';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import api from '../../api.js';
import { toast } from 'react-toastify';
import IconButton from 'components/common/IconButton';
import SoftBadge from 'components/common/SoftBadge';
import { useNavigate } from 'react-router-dom';
import Loading from 'components/utilities/Loading';
import { QueryContext } from 'context/Context';
import Moment from 'react-moment';

const ActionHandlerContext = React.createContext();

const columns = [
    {
        accessor: 'name',
        Header: 'Name',
        headerProps: { className: 'pe-1' },
        cellProps: {
            className: 'py-2'
        },
        Cell: rowData => {
            const { name } = rowData.row.original;
            return (
                <Flex alignItems="center">
                    <div className="flex-1">
                        <h5 className="mb-0 fs--1">{name}</h5>
                    </div>
                </Flex>
            );
        }
    },
    {
        accessor: 'none',
        Header: '',
        disableSortBy: true,
        disableButtons: true,
        cellProps: {
            className: 'text-end'
        },
        Cell: rowData => {
            const { id } = rowData.row.original;
            const { handleEdit, handleShowDelete } = React.useContext(ActionHandlerContext)
            return (
                <>
                    <IconButton
                        variant="falcon-default"
                        size="sm"
                        icon="edit"
                        transform="shrink-3"
                        className="mx-1"
                        onClick={() => {
                            handleEdit(id);
                        }}
                    >
                        <span className="d-none d-sm-inline-block ms-1">Edit</span>
                    </IconButton>
                    <IconButton
                        variant="danger"
                        size="sm"
                        icon="trash"
                        transform="shrink-3"
                        className="mx-1"
                        onClick={() => {
                            handleShowDelete(id);
                        }}
                    >
                        <span className="d-none d-sm-inline-block ms-1">Delete</span>
                    </IconButton>
                </>
            );
        }
    }
];

const SourceGroupList = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [activeSourceGroup, setActiveSourceGroup] = useState(0);
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(10);
    const [sortBy, setSortBy] = useState('name');
    const [direction, setDirection] = useState('asc');
    const [filter, setFilter] = useState('');
    const [totalPages, setTotalPages] = useState(0);
    const [totalElements, setTotalElements] = useState(0);

    const { updateQueries } = useContext(QueryContext);

    const handleClose = () => {
        setShow(false);
        setActiveSourceGroup(null);
    }

    const handleShowDelete = (sourceGroupId) => {
        setShow(true);
        setActiveSourceGroup(sourceGroupId);
    }

    const handleDelete = () => {
        setShow(false);
        if (activeSourceGroup !== null) {
            api.delete(`/api/source-groups/${activeSourceGroup}`)
                .then(response => {
                    if (response.status === 204) {
                        toast.success(`Deleted source groups succesfully.`, {
                            theme: 'colored'
                        });
                        // Filter out the deleted toneOfVoice and update state
                        const updatedSourceGroups = sourceGroups.filter(sourceGroup => sourceGroup.id !== activeSourceGroup);
                        setSourceGroups(updatedSourceGroups);
                    }

                })
                .catch(error => {
                    console.error("There was an error deleting the tone of voice:", error);
                });
        }
    }

    const handleLoadQueries = (page = 0, size = 10, sortBy = 'name', direction = 'asc', filter = '') => {
        setLoading(true);
        api.get('/api/source-groups', { params: { page, size, sortBy, direction, filter } })
            .then(response => {
                const success = response.data.success;
                setLoading(false);
                if (success) {
                    setSourceGroups(response.data.data);
                    setTotalPages(response.data.totalPages);
                    setTotalElements(response.data.totalElements);
                    updateQueries(response.data.data);
                } else {
                    toast.error('Failed to grab queries.', { theme: 'colored' });
                }
            })
            .catch(error => {
                setLoading(false);
                console.error('Error grabbing queries:', error);
                if (error.response && error.response.status === 401) {
                    navigate('/login');
                }
            });
    };

    const handleEdit = async (fetchId) => {
        if (fetchId !== null) {
            navigate(`/source-groups/${fetchId}/edit`);
        }
    };

    const [sourceGroups, setSourceGroups] = useState([]);

    useEffect(() => {
        handleLoadQueries(page, size, sortBy, direction, filter);
    }, [page, size, sortBy, direction, filter]);

    const handleSort = (column) => {
        const isAsc = sortBy === column && direction === 'asc';
        setDirection(isAsc ? 'desc' : 'asc');
        setSortBy(column);
    };

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const handlePageSizeChange = (newSize) => {
        setSize(newSize);
    };

    return (
        <>
            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this item? This action cannot be undone. Please ensure you have saved any necessary data related to this item before proceeding.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleDelete}>
                        Delete
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <ActionHandlerContext.Provider value={{ handleEdit, handleShowDelete }}>
                <AdvanceTableWrapper
                    columns={columns}
                    data={sourceGroups}
                    pagination
                    perPage={size}
                    setPageSize={handlePageSizeChange}
                    pageIndex={page}
                    setPage={handlePageChange}
                    sortBy={sortBy}
                    setSortBy={setSortBy}
                    sortDirection={direction}
                    setSortDirection={setDirection}
                    globalFilter={filter}
                >
                    <Card className="mb-3">
                        <Card.Header>
                            <SourceGroupListTableHeader table />
                        </Card.Header>
                        <Card.Body className="bg-light">
                            <Loading loading={loading} isCenter={true} size="lg">
                                <AdvanceTable
                                    table
                                    headerClassName="bg-200 text-900 text-nowrap align-middle"
                                    rowClassName="align-middle white-space-nowrap"
                                    tableProps={{
                                        bordered: true,
                                        striped: true,
                                        className: 'fs--1 mb-0'
                                    }}
                                />
                            </Loading>
                        </Card.Body>
                        <Card.Footer>
                            <AdvanceTablePagination
                                canPreviousPage={page > 0}
                                canNextPage={page < totalPages - 1}
                                previousPage={() => handlePageChange(page - 1)}
                                nextPage={() => handlePageChange(page + 1)}
                                pageCount={totalPages}
                                pageIndex={page}
                                gotoPage={handlePageChange}
                                totalElements={totalElements}
                            />

                        </Card.Footer>
                    </Card>
                </AdvanceTableWrapper>
            </ActionHandlerContext.Provider>
        </>
    );
};

export default SourceGroupList;
