import React, { useContext, useEffect, useState } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import { ContentViewContext } from 'context/Context';
import Loading from 'components/utilities/Loading';
import IconButton from 'components/common/IconButton';
import api from 'api.js';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import SoftBadge from 'components/common/SoftBadge';

const ContentViewContent = ({ content }) => {
  const [loading, setLoading] = useState(false);
  const [matchedContents, setMatchedContents] = useState([]);
  const sanitizedContent = DOMPurify.sanitize(content.contentPlain);
  const formattedContent = sanitizedContent.replace(/\n/g, '<br>');

  const { editingId, loadingEdit, handleSubmit, handleCancel } = useContext(ContentViewContext);
  const { id } = useParams();
  useEffect(() => {
    setLoading(true);
    api.get(`/api/content/${id}/matched-content`)
      .then(response => {
        setLoading(false);
        setMatchedContents(response.data.data);
      })
      .catch(error => {
        setLoading(false);
        toast.error(`Failed to grab content.`, {
          theme: 'colored'
        });
      });
  }, [content.id]);

  return (
    <Card className="p-0 mb-3">
      <Card.Header>
        <div className="fs--1 ms-2 flex-1">
          <h1 className="text-capitalize">{content.title}</h1>
        </div>
      </Card.Header>
      <Card.Body className="overflow-hidden">

        <Loading loading={loading} isCenter={true} size="lg">
          {matchedContents.length > 0 ? (
            <Row className="flex-center mb-5">
              <Col>
                <Flex>
                  <div className="fs--1 ms-2 flex-1">
                    <Row>
                      <h6 className="text-capitalize">Sources</h6>
                      {matchedContents.length == 0 ? (
                        <div className='text-center'>
                          <h2>No items found.</h2>
                        </div>
                      ) : matchedContents.slice(0, 4).map((matchedContent, index) => (
                        <Col xs={12} md={6} lg={3} key={index}>
                          <Link to={matchedContent.canonicalUrl}>
                            <Card className="source-box">
                              {matchedContent.imageUrl && <Card.Img src={matchedContent.imageUrl} variant='top' />}
                              <Card.Body>
                                <Card.Title as='h6' className='source-card-meta'>{matchedContent.title}</Card.Title>
                                {index == 0 ? (
                                  <SoftBadge key={index} pill bg='secondary' className='me-2 mb-2'>
                                    Primary
                                  </SoftBadge>
                                ) : (
                                  <SoftBadge key={index} pill bg='secondary' className='me-2 mb-2'>
                                    Secondary
                                  </SoftBadge>
                                )}
                              </Card.Body>
                            </Card>
                          </Link>
                        </Col>
                      ))}
                    </Row>
                  </div>
                </Flex>
              </Col>
            </Row>
          ) : null}
        </Loading>
        <Row className="flex-center">
          <Col>
            <Flex>
              <div className="ms-2 flex-1" id={`content-${content.id}`}>
                {formattedContent ? parse(formattedContent) : <p>Loading content...</p>}
              </div>
            </Flex>
          </Col>
        </Row>
        <Row className="flex-center">
          <Col>
            <Flex>
              <Loading loading={loadingEdit}>
                {!loadingEdit && editingId === content.id ? (
                  <div className="content-action-buttons">
                    <IconButton variant="primary" size="sm" icon={faSave} className="rounded-pill me-2 mb-1" iconAlign="right" onClick={() => handleSubmit(content.id)}>
                      Save
                    </IconButton>
                    <IconButton variant="danger" size="sm" icon={faTimes} className="rounded-pill me-2 mb-1" iconAlign="right" onClick={() => handleCancel(content.id)}>
                      Cancel
                    </IconButton>
                  </div>
                ) : null}
              </Loading>
            </Flex>
          </Col>
        </Row>
      </Card.Body>
    </Card >
  );
};

ContentViewContent.propTypes = {
  content: PropTypes.object.isRequired
};

export default ContentViewContent;
