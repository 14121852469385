import React, { useState, useEffect, useRef } from 'react';
import { Form, Row, Col, Button, FloatingLabel } from 'react-bootstrap';
import FalconComponentCard from 'components/common/FalconComponentCard';
import api from '../../api.js';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const ConnectionEdit = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [connectionData, setConnectionData] = useState({
        is_enabled: true,
        provider: 0
    });

    const handleCancel = (e) => {
        e.preventDefault(); // To prevent the form from submitting by default
        navigate('/connections');
    }

    const handleSubmit = (e) => {
        e.preventDefault(); // To prevent the form from submitting by default

        // Check if any field is set to 'default'
        if (connectionData.name === "" ||
            connectionData.provider === "" ||
            connectionData.provider_url === "") {
            toast.error("Please select all required fields before submitting.", {
                theme: 'colored'
            });
            return;
        }

        api.put(`/api/connections/${id}`, connectionData)
            .then(response => {
                const success = response.status;
                if (success == 200) {
                    toast.success(`Updated connection successfully`, {
                        theme: 'colored'
                    });
                    navigate('/connections');
                }
                else {
                    toast.error(`Failed to update connection.`, {
                        theme: 'colored'
                    });
                }
            })
            .catch(error => {
                console.error('Error updating connection in:', error);
            })

        // If everything is fine, proceed with your submission logic here
        console.log("Submit the form");
    };

    useEffect(() => {
        api.get(`/api/connections/${id}`)
            .then(response => {
                const success = response.status;
                if (success == 200) {
                    setConnectionData(response.data);
                    toast.success(`Grabbed connection successfully`, {
                        theme: 'colored'
                    });
                }
                else {
                    toast.error(`Failed to grab connection.`, {
                        theme: 'colored'
                    });
                }
            })
            .catch(error => {
                console.error('Error grabbing connection in:', error);
            });
    }, [id]);

    return (
        <FalconComponentCard>
            <FalconComponentCard.Header title="Edit Connection" light={false} noPreview={true}>
                <p className="mt-2 mb-0">
                    Send content and optimize your workflow.
                </p>
            </FalconComponentCard.Header>
            <FalconComponentCard.Body>
                <Form onSubmit={handleSubmit}>
                    <Row className='m-3 g-3' style={{ paddingBottom: '20px', marginBottom: '50px' }}>
                        <Col className="px-3" xs={12} md={12}>
                            <h4>Connection Settings</h4>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Connection Name"
                                placeholder=''
                            >
                                <Form.Control
                                    type="text"
                                    value={connectionData.name}
                                    onChange={e => setConnectionData({ ...connectionData, name: e.target.value })}
                                />
                            </FloatingLabel>
                        </Col>
                        <Col className="px-3" xs={12} md={12}>
                            <FloatingLabel controlId="floatingSelect" label="Provider">
                                <Form.Select value={connectionData.provider} onChange={e => setConnectionData({ ...connectionData, provider: e.target.value })}>
                                    <option value="0">Zapier</option>
                                </Form.Select>
                            </FloatingLabel>
                        </Col>
                        <Col className="px-3" xs={12} md={12}>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Webhook URL"
                            >
                                <Form.Control
                                    type="text"
                                    value={connectionData.url}
                                    onChange={e => setConnectionData({ ...connectionData, url: e.target.value })}
                                />
                            </FloatingLabel>
                        </Col>
                    </Row>

                    <Button variant="secondary" onClick={handleCancel} className='mx-1'>
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit" className='mx-1'>
                        Update Connection
                    </Button>
                </Form>
            </FalconComponentCard.Body>
        </FalconComponentCard >
    );
};

export default ConnectionEdit;
