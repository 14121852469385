import React, { useState, useRef, useEffect } from 'react';
import { Card, Dropdown, Modal, Button } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import ConnectionListTableHeader from './ConnectionListTableHeader';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import api from '../../api.js';
import { toast } from 'react-toastify';
import IconButton from 'components/common/IconButton';
import SoftBadge from 'components/common/SoftBadge';
import { useNavigate } from 'react-router-dom';
import Loading from 'components/utilities/Loading';
const ActionHandlerContext = React.createContext();

const columns = [
    {
        accessor: 'name',
        Header: 'Name',
        headerProps: { className: 'pe-1' },
        cellProps: {
            className: 'py-2'
        },
        Cell: rowData => {
            const { name } = rowData.row.original;
            return (
                <Flex alignItems="center">
                    <div className="flex-1">
                        <h5 className="mb-0 fs--1">{name}</h5>
                    </div>
                </Flex>
            );
        }
    },
    {
        accessor: 'provider',
        Header: 'Provider',
        Cell: rowData => {
            const { provider } = rowData.row.original;
            return <span style={{ textTransform: 'capitalize' }}>{provider.toLowerCase()}</span>;
        }
    },
    {
        accessor: 'none',
        Header: '',
        disableSortBy: true,
        cellProps: {
            className: 'text-end'
        },
        Cell: rowData => {
            const { id } = rowData.row.original;
            const { handleEdit, handleShow } = React.useContext(ActionHandlerContext)
            return (
                <>
                    <IconButton
                        variant="falcon-default"
                        size="sm"
                        icon="edit"
                        transform="shrink-3"
                        className="mx-1"
                        onClick={() => {
                            handleEdit(id);
                        }}
                    >
                        <span className="d-none d-sm-inline-block ms-1">Edit</span>
                    </IconButton>
                    <IconButton
                        variant="danger"
                        size="sm"
                        icon="trash"
                        transform="shrink-3"
                        className="mx-1"
                        onClick={() => {
                            handleShow(id);
                        }}
                    >
                        <span className="d-none d-sm-inline-block ms-1">Delete</span>
                    </IconButton>
                </>
            );
        }
    }
];

const ConnectionList = () => {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [activeConnection, setActiveConnection] = useState(null);

    const handleClose = () => {
        setShow(false);
        setActiveConnection(null);
    }

    const handleShow = (connectionId) => {
        setShow(true);
        setActiveConnection(connectionId);
    }

    const handleEdit = async (connectionId) => {
        if (connectionId !== null) {
            navigate(`/connections/${connectionId}/edit`);
        }
    };

    const handleDelete = async () => {
        setShow(false);
        if (activeConnection !== null) {
            api.delete(`/api/connections/${activeConnection}`)
                .then(response => {
                    if (response.status === 204) {
                        console.log("Connection deleted successfully");
                        toast.success(`Deleted connection succesfully.`, {
                            theme: 'colored'
                        });
                        // Filter out the deleted connection and update state
                        const updatedConnectiones = connections.filter(connection => connection.id !== activeConnection);
                        setConnectiones(updatedConnectiones);
                    }

                })
                .catch(error => {
                    console.error("There was an error deleting the project:", error);
                });
        }
    };

    const [connections, setConnectiones] = useState([]);

    useEffect(() => {
        setLoading(true);
        api.get('/api/connections')
            .then(response => {
                const success = response.data.success;
                setLoading(false);
                if (success) {
                    setConnectiones(response.data.data);
                    toast.success(`Grabbed connections successfully`, {
                        theme: 'colored'
                    });
                }
                else {
                    toast.error(`Failed to grab connections.`, {
                        theme: 'colored'
                    });
                }
            })
            .catch(error => {
                setLoading(false);
                console.error('Error grabbing connections in:', error);
            })
    }, []);

    return (
        <>
            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this item? This action cannot be undone. Please ensure you have saved any necessary data related to this item before proceeding.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleDelete}>
                        Delete
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <ActionHandlerContext.Provider value={{ handleEdit, handleShow }}>
                <AdvanceTableWrapper
                    columns={columns}
                    data={connections}
                    pagination
                    perPage={10}
                >
                    <Card className="mb-3">
                        <Card.Header>
                            <ConnectionListTableHeader table />
                        </Card.Header>
                        <Card.Body className="bg-light">
                            <Loading loading={loading} isCenter={true} size="lg">
                                <AdvanceTable
                                    table
                                    headerClassName="bg-200 text-900 text-nowrap align-middle"
                                    rowClassName="align-middle white-space-nowrap"
                                    tableProps={{
                                        bordered: true,
                                        striped: true,
                                        className: 'fs--1 mb-0'
                                    }}
                                />
                            </Loading>
                        </Card.Body>
                        <Card.Footer>
                            <AdvanceTablePagination table />
                        </Card.Footer>
                    </Card>
                </AdvanceTableWrapper>
            </ActionHandlerContext.Provider>
        </>
    );
};

export default ConnectionList;
