import React, { useState, useEffect, useRef } from 'react';
import { Form, Row, Col, Button, FloatingLabel } from 'react-bootstrap';
import FalconComponentCard from 'components/common/FalconComponentCard';
import api from '../../api.js';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const ToneOfVoiceEdit = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [toneOfVoice, setToneOfVoice] = useState({
        name: "",
        desiredAgeGroups: "",
        desiredGender: ""
    });

    const [characteristics, setCharacteristics] = useState({
        honesty: 50,
        openness: 50,
        transparency: 50,
        clarity: 50,
        directness: 50,
        professionalism: 50,
        politeness: 50,
        respectfulness: 50,
        inclusiveness: 50,
        concision: 50,
        approachability: 50,
        friendliness: 50,
        empathy: 50,
        positivity: 50,
        encouragement: 50,
        understanding: 50,
        receptiveness: 50,
        consideration: 50,
        sensitivity: 50,
        compassion: 50,
        reasonableness: 50,
        precision: 50,
        impartiality: 50,
        graciousness: 50,
        thoughtfulness: 50
    });

    const [ageGroups, setAgeGroups] = useState({
        '13-17': false,
        '18-24': false,
        '25-34': false,
        '35-44': false,
        '45-54': false,
        '55-64': false,
        '65+': false
    });

    const handleAgeGroupChange = (range) => {
        const newAgeGroups = {
            ...ageGroups,
            [range]: !ageGroups[range]
        };
        setAgeGroups(newAgeGroups);
    };

    // Function to map tone values to integers
    const mapToneValuesToInt = (toneValues) => {
        const toneMapping = {
            none: 0,
            low: 25,
            medium: 50,
            high: 75,
            extreme: 100
        };

        // Iterate over each property in the object
        for (let key in toneValues) {
            if (toneValues.hasOwnProperty(key)) {
                // Replace the string value with the corresponding integer
                toneValues[key] = toneMapping[toneValues[key]];
            }
        }
        return toneValues;
    }

    const handleCancel = (e) => {
        e.preventDefault(); // To prevent the form from submitting by default
        navigate('/tone-of-voice');
    }

    const handleSubmit = (e) => {
        console.log("Submit");
        e.preventDefault(); // To prevent the form from submitting by default

        // Check if any field is set to 'default'
        if (toneOfVoice.name === "") {
            toast.error("Please fill all required fields before submitting.", {
                theme: 'colored'
            });
            return;
        }

        toneOfVoice.characteristics = JSON.stringify(characteristics);

        toneOfVoice.desiredAgeGroups = JSON.stringify(ageGroups);

        api.put(`/api/tone-of-voice/${id}`, toneOfVoice)
            .then(response => {
                const success = response.status;
                if (success == 200) {
                    toast.success(`Updated tone of voice successfully`, {
                        theme: 'colored'
                    });

                    navigate('/tone-of-voice');
                }
                else {
                    toast.error(`Failed to update tone of voice.`, {
                        theme: 'colored'
                    });
                }
            })
            .catch(error => {
                console.error('Error creating tone of voice in:', error);
            });
    };

    useEffect(() => {
        api.get(`/api/tone-of-voice/${id}`)
            .then(response => {
                const success = response.status;
                if (success == 200) {
                    setToneOfVoice(response.data);

                    // Parse the toneOfVoice string into an object
                    if (response.data.toneOfVoice != null && response.data.toneOfVoice != '') {
                        const toneOfVoiceObject = JSON.parse(response.data.toneOfVoice);

                        // Map the string values to integers
                        setCharacteristics(mapToneValuesToInt(toneOfVoiceObject));
                    }


                    var desiredAgeGroups = response.data.desiredAgeGroups;
                    if (desiredAgeGroups && desiredAgeGroups != '') {
                        try {
                            // Attempt to parse the JSON string
                            const ageGroupsObject = JSON.parse(desiredAgeGroups);
                            setAgeGroups(ageGroupsObject);
                        } catch (error) {
                            console.error('Error parsing JSON:', error);
                        }
                    }

                    toast.success(`Grabbed tone of voice successfully`, {
                        theme: 'colored'
                    });
                }
                else {
                    toast.error(`Failed to grab tone of voice.`, {
                        theme: 'colored'
                    });
                }
            })
            .catch(error => {
                console.error('Error grabbing tone of voice in:', error);
            });
    }, [id]);

    return (
        <FalconComponentCard>
            <FalconComponentCard.Header title="Edit tone of voice" light={false} noPreview={true}>
                <p className="mt-2 mb-0">
                    Here you can modify details around your tone of voice including fine tuning your tone of voice model.
                </p>
            </FalconComponentCard.Header>
            <FalconComponentCard.Body>
                <Form onSubmit={handleSubmit}>
                    <Row className='m-3 g-3' style={{ paddingBottom: '20px', marginBottom: '50px' }}>
                        <Col className="px-3" xs={12} md={12}>
                            <Form.Group className="mb-3">
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Name"
                                    placeholder='Criminal justice'
                                >
                                    <Form.Control
                                        type="text"
                                        value={toneOfVoice.name}
                                        onChange={e => setToneOfVoice({ ...toneOfVoice, name: e.target.value })}
                                    />
                                </FloatingLabel>
                            </Form.Group>
                        </Col>
                        <Col className="px-3" xs={12} md={12}>
                            <Form.Group className="mb-3" controlId="toneOfVoiceGender">
                                <Form.Label>What gender is your audience?</Form.Label>
                                <Form.Select
                                    aria-label="eg. Male, Female, Unisex"
                                    value={toneOfVoice.desiredGender !== null && toneOfVoice.desiredGender !== undefined ? toneOfVoice.desiredGender : 'default'}
                                    onChange={e => setToneOfVoice({ ...toneOfVoice, desiredGender: e.target.value })}
                                >
                                    <option value="default">eg. Male, Female, Unisex</option>
                                    <option value="UNISEX">Unisex</option>
                                    <option value="MALE">Male</option>
                                    <option value="FEMALE">Female</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col className="px-3" xs={12} md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label>What age group is your audience?</Form.Label>
                                {Object.keys(ageGroups).map(range => (
                                    <Form.Check
                                        type="checkbox"
                                        id={`age-group-${range}`}
                                        label={range}
                                        checked={ageGroups[range]}
                                        onChange={() => handleAgeGroupChange(range)}
                                        key={range}
                                    />
                                ))}
                            </Form.Group>
                        </Col>
                        <Col className="p-3" xs={12} md={12}>
                            <h4 style={{ "textAlign": "center" }}>Voice Characteristics</h4>
                        </Col>
                        <Col className="p-3" xs={12} md={4} lg={3}>
                            <Form.Group className="mb-3" controlId="honesty">
                                <div style={{ "textAlign": "center" }}><strong>Honesty</strong></div>
                                <Form.Label>None</Form.Label>
                                <Form.Label style={{ "float": "right" }}>Extreme</Form.Label>
                                <Form.Range
                                    min='0'
                                    max='100'
                                    step='25'
                                    value={characteristics.honesty ? characteristics.honesty : '50'}
                                    onChange={e => setCharacteristics({ ...characteristics, honesty: e.target.value })}
                                />
                            </Form.Group>
                        </Col>
                        <Col className="p-3" xs={12} md={4} lg={3}>
                            <Form.Group className="mb-3" controlId="openness">
                                <div style={{ "textAlign": "center" }}><strong>Openness</strong></div>
                                <Form.Label>None</Form.Label>
                                <Form.Label style={{ "float": "right" }}>Extreme</Form.Label>
                                <Form.Range
                                    min='0'
                                    max='100'
                                    step='25'
                                    value={characteristics.openness ? characteristics.openness : '50'}
                                    onChange={e => setCharacteristics({ ...characteristics, openness: e.target.value })}
                                />
                            </Form.Group>
                        </Col>
                        <Col className="p-3" xs={12} md={4} lg={3}>
                            <Form.Group className="mb-3" controlId="transparency">
                                <div style={{ "textAlign": "center" }}><strong>Transparency</strong></div>
                                <Form.Label>None</Form.Label>
                                <Form.Label style={{ "float": "right" }}>Extreme</Form.Label>
                                <Form.Range
                                    min='0'
                                    max='100'
                                    step='25'
                                    value={characteristics.transparency ? characteristics.transparency : '50'}
                                    onChange={e => setCharacteristics({ ...characteristics, transparency: e.target.value })}
                                />
                            </Form.Group>
                        </Col>
                        <Col className="p-3" xs={12} md={4} lg={3}>
                            <Form.Group className="mb-3" controlId="clarity">
                                <div style={{ "textAlign": "center" }}><strong>Clarity</strong></div>
                                <Form.Label>None</Form.Label>
                                <Form.Label style={{ "float": "right" }}>Extreme</Form.Label>
                                <Form.Range
                                    min='0'
                                    max='100'
                                    step='25'
                                    value={characteristics.clarity ? characteristics.clarity : '50'}
                                    onChange={e => setCharacteristics({ ...characteristics, clarity: e.target.value })}
                                />
                            </Form.Group>
                        </Col>
                        <Col className="p-3" xs={12} md={4} lg={3}>
                            <Form.Group className="mb-3" controlId="directness">
                                <div style={{ "textAlign": "center" }}><strong>Directness</strong></div>
                                <Form.Label>None</Form.Label>
                                <Form.Label style={{ "float": "right" }}>Extreme</Form.Label>
                                <Form.Range
                                    min='0'
                                    max='100'
                                    step='25'
                                    value={characteristics.directness ? characteristics.directness : '50'}
                                    onChange={e => setCharacteristics({ ...characteristics, directness: e.target.value })}
                                />
                            </Form.Group>
                        </Col>
                        <Col className="p-3" xs={12} md={4} lg={3}>
                            <Form.Group className="mb-3" controlId="professionalism">
                                <div style={{ "textAlign": "center" }}><strong>Professionalism</strong></div>
                                <Form.Label>None</Form.Label>
                                <Form.Label style={{ "float": "right" }}>Extreme</Form.Label>
                                <Form.Range
                                    min='0'
                                    max='100'
                                    step='25'
                                    value={characteristics.professionalism ? characteristics.professionalism : '50'}
                                    onChange={e => setCharacteristics({ ...characteristics, professionalism: e.target.value })}
                                />
                            </Form.Group>
                        </Col>
                        <Col className="p-3" xs={12} md={4} lg={3}>
                            <Form.Group className="mb-3" controlId="politeness">
                                <div style={{ "textAlign": "center" }}><strong>Politeness</strong></div>
                                <Form.Label>None</Form.Label>
                                <Form.Label style={{ "float": "right" }}>Extreme</Form.Label>
                                <Form.Range
                                    min='0'
                                    max='100'
                                    step='25'
                                    value={characteristics.politeness ? characteristics.politeness : '50'}
                                    onChange={e => setCharacteristics({ ...characteristics, politeness: e.target.value })}
                                />
                            </Form.Group>
                        </Col>
                        <Col className="p-3" xs={12} md={4} lg={3}>
                            <Form.Group className="mb-3" controlId="respectfulness">
                                <div style={{ "textAlign": "center" }}><strong>Respectfulness</strong></div>
                                <Form.Label>None</Form.Label>
                                <Form.Label style={{ "float": "right" }}>Extreme</Form.Label>
                                <Form.Range
                                    min='0'
                                    max='100'
                                    step='25'
                                    value={characteristics.respectfulness ? characteristics.respectfulness : '50'}
                                    onChange={e => setCharacteristics({ ...characteristics, respectfulness: e.target.value })}
                                />
                            </Form.Group>
                        </Col>
                        <Col className="p-3" xs={12} md={4} lg={3}>
                            <Form.Group className="mb-3" controlId="inclusiveness">
                                <div style={{ "textAlign": "center" }}><strong>Inclusiveness</strong></div>
                                <Form.Label>None</Form.Label>
                                <Form.Label style={{ "float": "right" }}>Extreme</Form.Label>
                                <Form.Range
                                    min='0'
                                    max='100'
                                    step='25'
                                    value={characteristics.inclusiveness ? characteristics.inclusiveness : '50'}
                                    onChange={e => setCharacteristics({ ...characteristics, inclusiveness: e.target.value })}
                                />
                            </Form.Group>
                        </Col>
                        <Col className="p-3" xs={12} md={4} lg={3}>
                            <Form.Group className="mb-3" controlId="concision">
                                <div style={{ "textAlign": "center" }}><strong>Concision</strong></div>
                                <Form.Label>None</Form.Label>
                                <Form.Label style={{ "float": "right" }}>Extreme</Form.Label>
                                <Form.Range
                                    min='0'
                                    max='100'
                                    step='25'
                                    value={characteristics.concision ? characteristics.concision : '50'}
                                    onChange={e => setCharacteristics({ ...characteristics, concision: e.target.value })}
                                />
                            </Form.Group>
                        </Col>
                        <Col className="p-3" xs={12} md={4} lg={3}>
                            <Form.Group className="mb-3" controlId="approachability">
                                <div style={{ "textAlign": "center" }}><strong>Approachability</strong></div>
                                <Form.Label>None</Form.Label>
                                <Form.Label style={{ "float": "right" }}>Extreme</Form.Label>
                                <Form.Range
                                    min='0'
                                    max='100'
                                    step='25'
                                    value={characteristics.approachability ? characteristics.approachability : '50'}
                                    onChange={e => setCharacteristics({ ...characteristics, approachability: e.target.value })}
                                />
                            </Form.Group>
                        </Col>
                        <Col className="p-3" xs={12} md={4} lg={3}>
                            <Form.Group className="mb-3" controlId="friendliness">
                                <div style={{ "textAlign": "center" }}><strong>Friendliness</strong></div>
                                <Form.Label>None</Form.Label>
                                <Form.Label style={{ "float": "right" }}>Extreme</Form.Label>
                                <Form.Range
                                    min='0'
                                    max='100'
                                    step='25'
                                    value={characteristics.friendliness ? characteristics.friendliness : '50'}
                                    onChange={e => setCharacteristics({ ...characteristics, friendliness: e.target.value })}
                                />
                            </Form.Group>
                        </Col>
                        <Col className="p-3" xs={12} md={4} lg={3}>
                            <Form.Group className="mb-3" controlId="empathy">
                                <div style={{ "textAlign": "center" }}><strong>Empathy</strong></div>
                                <Form.Label>None</Form.Label>
                                <Form.Label style={{ "float": "right" }}>Extreme</Form.Label>
                                <Form.Range
                                    min='0'
                                    max='100'
                                    step='25'
                                    value={characteristics.empathy ? characteristics.empathy : '50'}
                                    onChange={e => setCharacteristics({ ...characteristics, empathy: e.target.value })}
                                />
                            </Form.Group>
                        </Col>
                        <Col className="p-3" xs={12} md={4} lg={3}>
                            <Form.Group className="mb-3" controlId="positivity">
                                <div style={{ "textAlign": "center" }}><strong>Positivity</strong></div>
                                <Form.Label>None</Form.Label>
                                <Form.Label style={{ "float": "right" }}>Extreme</Form.Label>
                                <Form.Range
                                    min='0'
                                    max='100'
                                    step='25'
                                    value={characteristics.positivity ? characteristics.positivity : '50'}
                                    onChange={e => setCharacteristics({ ...characteristics, positivity: e.target.value })}
                                />
                            </Form.Group>
                        </Col>
                        <Col className="p-3" xs={12} md={4} lg={3}>
                            <Form.Group className="mb-3" controlId="encouragement">
                                <div style={{ "textAlign": "center" }}><strong>Encouragement</strong></div>
                                <Form.Label>None</Form.Label>
                                <Form.Label style={{ "float": "right" }}>Extreme</Form.Label>
                                <Form.Range
                                    min='0'
                                    max='100'
                                    step='25'
                                    value={characteristics.encouragement ? characteristics.encouragement : '50'}
                                    onChange={e => setCharacteristics({ ...characteristics, encouragement: e.target.value })}
                                />
                            </Form.Group>
                        </Col>
                        <Col className="p-3" xs={12} md={4} lg={3}>
                            <Form.Group className="mb-3" controlId="understanding">
                                <div style={{ "textAlign": "center" }}><strong>Understanding</strong></div>
                                <Form.Label>None</Form.Label>
                                <Form.Label style={{ "float": "right" }}>Extreme</Form.Label>
                                <Form.Range
                                    min='0'
                                    max='100'
                                    step='25'
                                    value={characteristics.understanding ? characteristics.understanding : '50'}
                                    onChange={e => setCharacteristics({ ...characteristics, understanding: e.target.value })}
                                />
                            </Form.Group>
                        </Col>
                        <Col className="p-3" xs={12} md={4} lg={3}>
                            <Form.Group className="mb-3" controlId="receptiveness">
                                <div style={{ "textAlign": "center" }}><strong>Receptiveness</strong></div>
                                <Form.Label>None</Form.Label>
                                <Form.Label style={{ "float": "right" }}>Extreme</Form.Label>
                                <Form.Range
                                    min='0'
                                    max='100'
                                    step='25'
                                    value={characteristics.receptiveness ? characteristics.receptiveness : '50'}
                                    onChange={e => setCharacteristics({ ...characteristics, receptiveness: e.target.value })}
                                />
                            </Form.Group>
                        </Col>
                        <Col className="p-3" xs={12} md={4} lg={3}>
                            <Form.Group className="mb-3" controlId="consideration">
                                <div style={{ "textAlign": "center" }}><strong>Consideration</strong></div>
                                <Form.Label>None</Form.Label>
                                <Form.Label style={{ "float": "right" }}>Extreme</Form.Label>
                                <Form.Range
                                    min='0'
                                    max='100'
                                    step='25'
                                    value={characteristics.consideration ? characteristics.consideration : '50'}
                                    onChange={e => setCharacteristics({ ...characteristics, consideration: e.target.value })}
                                />
                            </Form.Group>
                        </Col>
                        <Col className="p-3" xs={12} md={4} lg={3}>
                            <Form.Group className="mb-3" controlId="sensitivity">
                                <div style={{ "textAlign": "center" }}><strong>Sensitivity</strong></div>
                                <Form.Label>None</Form.Label>
                                <Form.Label style={{ "float": "right" }}>Extreme</Form.Label>
                                <Form.Range
                                    min='0'
                                    max='100'
                                    step='25'
                                    value={characteristics.sensitivity ? characteristics.sensitivity : '50'}
                                    onChange={e => setCharacteristics({ ...characteristics, sensitivity: e.target.value })}
                                />
                            </Form.Group>
                        </Col>
                        <Col className="p-3" xs={12} md={4} lg={3}>
                            <Form.Group className="mb-3" controlId="compassion">
                                <div style={{ "textAlign": "center" }}><strong>Compassion</strong></div>
                                <Form.Label>None</Form.Label>
                                <Form.Label style={{ "float": "right" }}>Extreme</Form.Label>
                                <Form.Range
                                    min='0'
                                    max='100'
                                    step='25'
                                    value={characteristics.compassion ? characteristics.compassion : '50'}
                                    onChange={e => setCharacteristics({ ...characteristics, compassion: e.target.value })}
                                />
                            </Form.Group>
                        </Col>
                        <Col className="p-3" xs={12} md={4} lg={3}>
                            <Form.Group className="mb-3" controlId="reasonableness">
                                <div style={{ "textAlign": "center" }}><strong>Reasonableness</strong></div>
                                <Form.Label>None</Form.Label>
                                <Form.Label style={{ "float": "right" }}>Extreme</Form.Label>
                                <Form.Range
                                    min='0'
                                    max='100'
                                    step='25'
                                    value={characteristics.reasonableness ? characteristics.reasonableness : '50'}
                                    onChange={e => setCharacteristics({ ...characteristics, reasonableness: e.target.value })}
                                />
                            </Form.Group>
                        </Col>
                        <Col className="p-3" xs={12} md={4} lg={3}>
                            <Form.Group className="mb-3" controlId="precision">
                                <div style={{ "textAlign": "center" }}><strong>Precision</strong></div>
                                <Form.Label>None</Form.Label>
                                <Form.Label style={{ "float": "right" }}>Extreme</Form.Label>
                                <Form.Range
                                    min='0'
                                    max='100'
                                    step='25'
                                    value={characteristics.precision ? characteristics.precision : '50'}
                                    onChange={e => setCharacteristics({ ...characteristics, precision: e.target.value })}
                                />
                            </Form.Group>
                        </Col>
                        <Col className="p-3" xs={12} md={4} lg={3}>
                            <Form.Group className="mb-3" controlId="impartiality">
                                <div style={{ "textAlign": "center" }}><strong>Impartiality</strong></div>
                                <Form.Label>None</Form.Label>
                                <Form.Label style={{ "float": "right" }}>Extreme</Form.Label>
                                <Form.Range
                                    min='0'
                                    max='100'
                                    step='25'
                                    value={characteristics.impartiality ? characteristics.impartiality : '50'}
                                    onChange={e => setCharacteristics({ ...characteristics, impartiality: e.target.value })}
                                />
                            </Form.Group>
                        </Col>
                        <Col className="p-3" xs={12} md={4} lg={3}>
                            <Form.Group className="mb-3" controlId="graciousness">
                                <div style={{ "textAlign": "center" }}><strong>Graciousness</strong></div>
                                <Form.Label>None</Form.Label>
                                <Form.Label style={{ "float": "right" }}>Extreme</Form.Label>
                                <Form.Range
                                    min='0'
                                    max='100'
                                    step='25'
                                    value={characteristics.graciousness ? characteristics.graciousness : '50'}
                                    onChange={e => setCharacteristics({ ...characteristics, graciousness: e.target.value })}
                                />
                            </Form.Group>
                        </Col>
                        <Col className="p-3" xs={12} md={4} lg={3}>
                            <Form.Group className="mb-3" controlId="thoughtfulness">
                                <div style={{ "textAlign": "center" }}><strong>Thoughtfulness</strong></div>
                                <Form.Label>None</Form.Label>
                                <Form.Label style={{ "float": "right" }}>Extreme</Form.Label>
                                <Form.Range
                                    min='0'
                                    max='100'
                                    step='25'
                                    value={characteristics.thoughtfulness ? characteristics.thoughtfulness : '50'}
                                    onChange={e => setCharacteristics({ ...characteristics, thoughtfulness: e.target.value })}
                                />
                            </Form.Group>
                        </Col>
                        <Col className="p-3" xs={12} md={4} lg={3}>
                            <Form.Group className="mb-3" controlId="adaptability">
                                <div style={{ "textAlign": "center" }}><strong>Adaptability</strong></div>
                                <Form.Label>None</Form.Label>
                                <Form.Label style={{ "float": "right" }}>Extreme</Form.Label>
                                <Form.Range
                                    min='0'
                                    max='100'
                                    step='25'
                                    value={characteristics.adaptability ? characteristics.adaptability : '50'}
                                    onChange={e => setCharacteristics({ ...characteristics, adaptability: e.target.value })}
                                />
                            </Form.Group>
                        </Col>
                        <Col className="p-3" xs={12} md={4} lg={3}>
                            <Form.Group className="mb-3" controlId="confidence">
                                <div style={{ "textAlign": "center" }}><strong>Confidence</strong></div>
                                <Form.Label>None</Form.Label>
                                <Form.Label style={{ "float": "right" }}>Extreme</Form.Label>
                                <Form.Range
                                    min='0'
                                    max='100'
                                    step='25'
                                    value={characteristics.confidence ? characteristics.confidence : '50'}
                                    onChange={e => setCharacteristics({ ...characteristics, confidence: e.target.value })}
                                />
                            </Form.Group>
                        </Col>
                        <Col className="p-3" xs={12} md={4} lg={3}>
                            <Form.Group className="mb-3" controlId="curiosity">
                                <div style={{ "textAlign": "center" }}><strong>Curiosity</strong></div>
                                <Form.Label>None</Form.Label>
                                <Form.Label style={{ "float": "right" }}>Extreme</Form.Label>
                                <Form.Range
                                    min='0'
                                    max='100'
                                    step='25'
                                    value={characteristics.curiosity ? characteristics.curiosity : '50'}
                                    onChange={e => setCharacteristics({ ...characteristics, curiosity: e.target.value })}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Button variant="secondary" onClick={handleCancel} className='mx-1'>
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit" className='mx-1'>
                        Update Tone of Voice
                    </Button>
                </Form>
            </FalconComponentCard.Body>
        </FalconComponentCard >
    );
};

export default ToneOfVoiceEdit;
