import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import IconButton from 'components/common/IconButton';
import {
    Button,
    Col,
    Dropdown,
    Form,
    FormControl,
    InputGroup,
    Row,
    Modal
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import ContentQuickCreate from './ContentQuickCreate';

const NuggetListHeader = ({
    selectedRowIds,
    globalFilter,
    setGlobalFilter,
    layout,
    handleShow,
    selectedItems,
    handleContentSearch
}) => {

    return (
        <>
            <Row className="flex-between-center gy-2 px-x1">
                <Col xs={4} sm="auto" className="d-flex justify-content-start align-items-center pe-0">
                    <h4 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Results</h4>
                </Col>
                <Col xs="auto" className="d-flex justify-content-end align-items-center pe-0">
                    <InputGroup className="position-relative input-search-width">

                        <FormControl
                            size="sm"
                            id="search"
                            type="search"
                            className="shadow-none"
                            placeholder="Search by name"
                            onChange={e => handleContentSearch(e.target.value)}
                        />
                        <Button
                            size="sm"
                            variant="outline-secondary"
                            className="border-300 hover-border-secondary"
                        >
                            <FontAwesomeIcon icon="search" className="fs--1" />
                        </Button>
                    </InputGroup>
                </Col>
            </Row>
        </>

    );
};

NuggetListHeader.propTypes = {
    selectedRowIds: PropTypes.object,
    globalFilter: PropTypes.string,
    setGlobalFilter: PropTypes.func,
    handleShow: PropTypes.func,
    layout: PropTypes.string,
    selectedItems: PropTypes.array,
    handleContentSearch: PropTypes.func
};

export default NuggetListHeader;
