import React, { useState, useRef, useEffect } from 'react';
import { Card, Dropdown, Modal, Button, Form, Tabs, Tab, Row, Col } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import SourceListTableHeader from './SourceListTableHeader';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import api from '../../api.js';
import { toast } from 'react-toastify';
import IconButton from 'components/common/IconButton';
import SoftBadge from 'components/common/SoftBadge';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRss, faGlobe, faHandPointer, faTextHeight, faUsers, faChevronRight, faList, faAlignJustify } from '@fortawesome/free-solid-svg-icons'
import Moment from 'react-moment';
import Loading from 'components/utilities/Loading';
const ActionHandlerContext = React.createContext();

const columns = [
    {
        accessor: 'name',
        Header: 'Name',
        headerProps: { className: 'pe-1' },
        cellProps: {
            className: 'py-2'
        },
        Cell: rowData => {
            const { name, imageUrl } = rowData.row.original;
            return (
                <Flex alignItems="center">
                    <div className="flex-1">
                        <h5 className="mb-0 fs--1">{name}</h5>
                    </div>
                </Flex>
            );
        }
    },
    {
        accessor: 'type',
        Header: 'Type',
        Cell: rowData => {
            const { type } = rowData.row.original;
            if (type == 0) {
                return <><FontAwesomeIcon icon={faRss} /> RSS</>;
            } else {
                return <><FontAwesomeIcon icon={faGlobe} /> Website</>;
            }
        }
    },
    {
        accessor: 'none',
        Header: '',
        disableSortBy: true,
        disableButtons: true,
        cellProps: {
            className: 'text-end'
        },
        Cell: rowData => {
            const { id } = rowData.row.original;
            const sourceType = rowData.data.sourceType;
            const { handleViewRawContent, handleEdit, handleShowDelete } = React.useContext(ActionHandlerContext)
            console.log(rowData);
            return (
                <>
                    <IconButton
                        variant="falcon-default"
                        size="sm"
                        icon="eye"
                        transform="shrink-3"
                        className="mx-1"
                        onClick={() => {
                            handleViewRawContent(id);
                        }}
                    >
                        <span className="d-none d-sm-inline-block ms-1">View Raw Content</span>
                    </IconButton>
                    {sourceType == "private" ? (
                        <>
                            <IconButton
                                variant="falcon-default"
                                size="sm"
                                icon="edit"
                                transform="shrink-3"
                                className="mx-1"
                                onClick={() => {
                                    handleEdit(id);
                                }}
                            >
                                <span className="d-none d-sm-inline-block ms-1">Edit</span>
                            </IconButton>
                            <IconButton
                                variant="danger"
                                size="sm"
                                icon="trash"
                                transform="shrink-3"
                                className="mx-1"
                                onClick={() => {
                                    handleShowDelete(id);
                                }}
                            >
                                <span className="d-none d-sm-inline-block ms-1">Delete</span>
                            </IconButton>
                        </>
                    ) : ""}
                </>
            );
        }
    }
];

const SourceList = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState({
        isLoadingSources: false
    });
    const [showModal, setModalShow] = useState(false);
    const [sourceData, setSourceData] = useState({});
    const [sources, setSources] = useState([]);
    const [key, setKey] = useState('private');

    const hasRun = useRef(false);
    useEffect(() => {
        if (!hasRun.current) {
            hasRun.current = true;
            handleLoadPrivateSources();
        }
    }, []);

    const handleLoadPublicSources = () => {
        setKey('public');
        setLoading({
            ...loading,
            isLoadingSources: true
        });
        api.get('/api/sources/all/public')
            .then(response => {
                const success = response.data.success;
                if (success) {
                    response.data.data.sourceType = "public";
                    setSources(response.data.data);
                    toast.success(`Grabbed sources successfully`, {
                        theme: 'colored'
                    });
                }
                else {
                    toast.error(`Failed to grab sources.`, {
                        theme: 'colored'
                    });
                }
                setLoading({
                    ...loading,
                    isLoadingSources: false
                });
            })
            .catch(error => {
                setLoading({
                    ...loading,
                    isLoadingSources: false
                });
                console.error('Error grabbing sources in:', error);
            });
    }

    const handleLoadPrivateSources = () => {
        setKey('private');
        setLoading({
            ...loading,
            isLoadingSources: true
        });
        api.get('/api/sources/all/private')
            .then(response => {
                const success = response.data.success;
                if (success) {
                    response.data.data.sourceType = "private";
                    setSources(response.data.data);
                    toast.success(`Grabbed sources successfully`, {
                        theme: 'colored'
                    });
                }
                else {
                    toast.error(`Failed to grab sources.`, {
                        theme: 'colored'
                    });
                }
                setLoading({
                    ...loading,
                    isLoadingSources: false
                });
            })
            .catch(error => {
                setLoading({
                    ...loading,
                    isLoadingSources: false
                });
                console.error('Error grabbing sources in:', error);
            });
    }

    const handleTabChange = (tab) => {
        console.log(tab);
        if (tab == 'public') {
            handleLoadPublicSources();
        } else {
            handleLoadPrivateSources();
        }
    }

    const handleViewRawContent = (sourceId) => {
        navigate(`/sources/${sourceId}/raw-content`);
    }

    const handleEdit = (sourceId) => {
        navigate(`/sources/${sourceId}/edit`);
    }

    const [showDelete, setShowDelete] = useState(false);
    const [activeSource, setActiveSource] = useState(null);

    const handleCloseDelete = () => {
        setShowDelete(false);
        setActiveSource(null);
    }

    const handleShowDelete = (sourceId) => {
        setShowDelete(true);
        setActiveSource(sourceId);
    }

    const handleDelete = async () => {
        setShowDelete(false);
        if (activeSource !== null) {
            api.delete(`/api/sources/${activeSource}`)
                .then(response => {
                    if (response.status === 204) {
                        console.log("Source deleted successfully");
                        toast.success(`Source deleted successfully`, {
                            theme: 'colored'
                        });
                        // Filter out the deleted source and update state
                        const updatedSources = sources.filter(source => source.id !== activeSource);
                        setSources(updatedSources);
                    }

                })
                .catch(error => {
                    console.error("There was an error deleting the project:", error);
                });
        }
    };

    const handleSubmit = (e) => {
        console.log("Submit");
        e.preventDefault(); // To prevent the form from submitting by default

        // Check if any field is set to 'default'
        if (sourceData.name === "" || sourceData.url === "") {
            toast.error("Please fill all required fields before submitting.", {
                theme: 'colored'
            });
            return;
        }

        api.post(`/api/sources`, sourceData)
            .then(response => {
                const success = response.status;
                if (success == 201) {
                    toast.success(`Created new source successfully`, {
                        theme: 'colored'
                    });
                    navigate('/sources');
                }
                else {
                    toast.error(`Failed to create source.`, {
                        theme: 'colored'
                    });
                }
            })
            .catch(error => {
                console.error('Error creating source in:', error);
            })

        // If everything is fine, proceed with your submission logic here
        console.log("Submit the form");
    };

    return (
        <>
            {/* <Modal
                show={showModal}
                onHide={() => setModalShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Form onSubmit={handleSubmit}>
                    <Modal.Body>
                        <Form.Group className="mb-3" controlId="sourceName">
                            <Form.Label>Source Name</Form.Label>
                            <Form.Control size="lg"
                                type="text"
                                placeholder="BBC World"
                                required={true}
                                value={sourceData.name || ''}
                                onChange={e => setSourceData({ ...sourceData, name: e.target.value })} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="sourceUrl">
                            <Form.Label>Source URL</Form.Label>
                            <Form.Control size="lg"
                                type="text"
                                placeholder="https://www.bbc.com/news/world/australia"
                                required={true}
                                value={sourceData.url || ''}
                                onChange={e => setSourceData({ ...sourceData, url: e.target.value })} />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" type="submit" className='mx-1'>
                            Add source
                        </Button>
                        <Button onClick={() => setModalShow(false)} variant="secondary">Close</Button>
                    </Modal.Footer>
                </Form>
            </Modal> */}
            <Modal show={showDelete} onHide={handleCloseDelete} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this item? This action cannot be undone. Please ensure you have saved any necessary data related to this item before proceeding.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleDelete}>
                        Delete
                    </Button>
                    <Button variant="primary" onClick={handleCloseDelete}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <ActionHandlerContext.Provider value={{ handleViewRawContent, handleEdit, handleShowDelete }}>
                <AdvanceTableWrapper
                    columns={columns}
                    data={sources}
                    pagination
                    perPage={10}
                >
                    <Card className="mb-3">
                        <Card.Header>
                            <SourceListTableHeader table setModalShow={setModalShow} />
                        </Card.Header>
                        <Card.Body className="bg-light">
                            <Tabs
                                id="controlled-tab-example"
                                activeKey={key}
                                onSelect={(k) => handleTabChange(k)}
                            >
                                <Tab eventKey="private" title="Private" className='border-bottom border-x p-3'>
                                    <Loading loading={loading.isLoadingSources} isCenter={true} size="lg">
                                        <AdvanceTable
                                            table
                                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                                            rowClassName="align-middle white-space-nowrap"
                                            tableProps={{
                                                bordered: true,
                                                striped: true,
                                                className: 'fs--1 mb-0'
                                            }}
                                        />
                                    </Loading>
                                </Tab>
                                <Tab eventKey="public" title="Public" className='border-bottom border-x p-3'>
                                    <Loading loading={loading.isLoadingSources} isCenter={true} size="lg">
                                        <AdvanceTable
                                            table
                                            headerClassName="bg-200 text-900 text-nowrap align-middle"
                                            rowClassName="align-middle white-space-nowrap"
                                            tableProps={{
                                                bordered: true,
                                                striped: true,
                                                className: 'fs--1 mb-0'
                                            }}
                                        />
                                    </Loading>
                                </Tab>
                            </Tabs>

                        </Card.Body>
                        <Card.Footer>
                            <AdvanceTablePagination table />
                        </Card.Footer>
                    </Card>
                </AdvanceTableWrapper>
            </ActionHandlerContext.Provider>
        </>
    );
};

export default SourceList;
