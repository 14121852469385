import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Button } from 'react-bootstrap';
import Flex from '../Flex';

export const AdvanceTablePagination = ({
  canPreviousPage,
  canNextPage,
  previousPage,
  nextPage,
  pageCount,
  pageIndex,
  gotoPage,
  totalElements,
}) => {

  const startPage = 0;
  const endPage = pageCount - 1;

  const renderPageNumbers = () => {
    const pages = [];
    const start = Math.max(pageIndex - 2, startPage);
    const end = Math.min(pageIndex + 2, endPage);

    for (let i = start; i <= end; i++) {
      pages.push(i);
    }

    return pages;
  };

  return (
    <Flex alignItems="center" justifyContent="center">
      <Button
        size="sm"
        variant="falcon-default"
        onClick={() => gotoPage(startPage)}
        className={classNames('mx-2', { disabled: !canPreviousPage })}
      >
        <FontAwesomeIcon icon="angle-double-left" />
      </Button>
      <Button
        size="sm"
        variant="falcon-default"
        onClick={() => previousPage()}
        className={classNames({ disabled: !canPreviousPage })}
      >
        <FontAwesomeIcon icon="chevron-left" />
      </Button>
      <ul className="pagination mb-0 mx-2">
        {renderPageNumbers().map((page, index) => (
          <li key={page} className={classNames({ active: pageIndex === page })}>
            <Button
              size="sm"
              variant="falcon-default"
              className={classNames('page', {
                'me-2': index + 1 !== pageCount,
              })}
              onClick={() => gotoPage(page)}
            >
              {page + 1}
            </Button>
          </li>
        ))}
      </ul>
      <Button
        size="sm"
        variant="falcon-default"
        onClick={() => nextPage()}
        className={classNames({ disabled: !canNextPage })}
      >
        <FontAwesomeIcon icon="chevron-right" />
      </Button>
      <Button
        size="sm"
        variant="falcon-default"
        onClick={() => gotoPage(endPage)}
        className={classNames('mx-2', { disabled: pageIndex === endPage })}
      >
        <FontAwesomeIcon icon="angle-double-right" />
      </Button>
    </Flex>
  );
};

export default AdvanceTablePagination;
