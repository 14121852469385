import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Container, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { DragDropContext } from 'react-beautiful-dnd';
import TagItem from './TagItem';
import TagList from './TagList';
import StrictModeDroppable from './StrictModeDroppable';
import { v4 as uuidv4 } from 'uuid';

// const tagFilter = [
//     {
//         id: 'tag-1',
//         type: 'tag',
//         tag: 'football',
//         operation: 'AND'
//     },
//     {
//         id: 'tag-1',
//         type: 'tag',
//         tag: 'Messi',
//         operation: 'AND'
//     },
//     {
//         id: 'taggroup-10000004',
//         type: 'taggroup',
//         contents: [
//             {
//                 id: 'tag-2',
//                 type: 'tag',
//                 tag: 'injury',
//                 operation: 'OR'
//             },
//             {
//                 id: 'tag-3',
//                 type: 'tag',
//                 tag: 'killed',
//                 operation: 'OR'
//             }
//         ]
//     }

// ];

const TagSelector = ({ tagFilter, setTagFilter }) => {
    tagFilter = tagFilter || [

    ];
    const [currentDraggedItemId, setCurrentDraggedItemId] = useState(null);
    const [showDropdown, setShowDropdown] = useState(false);

    const handleTagChange = (id, newValue) => {
        // Map through the tagFilter array.
        const updatedData = tagFilter.map(item => {
            // If the item's ID matches the provided id, update its text.
            if (item.id === id) {
                return { ...item, tag: newValue };
            }

            // If the item is a taggroup, map through its contents to update the nested item.
            if (item.type === 'taggroup' && item.contents) {
                return {
                    ...item,
                    contents: item.contents.map(tag => {
                        if (tag.id === id) {
                            return { ...tag, tag: newValue };
                        }
                        return tag;
                    })
                };
            }

            // If no match is found, simply return the item.
            return item;
        });

        // Update the state with the new data.
        setTagFilter(updatedData);
    };

    const handleConditionChange = (id, newValue) => {
        // Map through the tagFilter array.
        const updatedData = tagFilter.map(item => {
            // If the item's ID matches the provided id, update its text.
            if (item.id === id) {
                return { ...item, condition: newValue };
            }

            // If the item is a taggroup, map through its contents to update the nested item.
            if (item.type === 'taggroup' && item.contents) {
                return {
                    ...item,
                    contents: item.contents.map(tag => {
                        if (tag.id === id) {
                            return { ...tag, condition: newValue };
                        }
                        return tag;
                    })
                };
            }

            // If no match is found, simply return the item.
            return item;
        });

        // Update the state with the new data.
        setTagFilter(updatedData);
    };

    const handleOperationChange = (id, newValue) => {
        // Map through the tagFilter array.
        const updatedData = tagFilter.map(item => {
            // If the item's ID matches the provided id, update its text.
            if (item.id === id) {
                return { ...item, operation: newValue };
            }

            // If the item is a taggroup, map through its contents to update the nested item.
            if (item.type === 'taggroup' && item.contents) {
                return {
                    ...item,
                    contents: item.contents.map(tag => {
                        if (tag.id === id) {
                            return { ...tag, operation: newValue };
                        }
                        return tag;
                    })
                };
            }

            // If no match is found, simply return the item.
            return item;
        });

        // Update the state with the new data.
        setTagFilter(updatedData);
    };

    const onDragStart = (start) => {
        console.log("onDragStart");
        console.log("draggable", tagFilter);
        setCurrentDraggedItemId(start.draggableId);
    };

    const onDragEnd = (result) => {
        console.log("onDragEnd");
        console.log("draggable", tagFilter);
        const { source, destination, draggableId } = result;

        setCurrentDraggedItemId(null);

        // If the item is dropped outside any list, do nothing
        if (!destination) {
            return;
        }

        const sourceId = source.droppableId;
        const destId = destination.droppableId;

        if (draggableId === destId) {
            return;
        }

        // Create a shallow copy of your data so we don't directly modify state
        const newData = [...tagFilter];

        const startList = getItemListById(sourceId, newData);
        const endList = getItemListById(destId, newData);

        if (!startList || !endList) {
            return;
        }

        // If we're moving within the same list
        if (sourceId === destId) {
            const [removed] = startList.splice(source.index, 1);
            startList.splice(destination.index, 0, removed);
        } else {
            // Moving between lists
            const [removed] = startList.splice(source.index, 1);
            endList.splice(destination.index, 0, removed);
        }

        // Update the state with the new data order
        setTagFilter(newData);
    };

    const deleteItem = (itemId) => {
        // Filter out the item with the specified id from the top-level
        let updatedData = tagFilter.filter(item => item.id !== itemId);

        // Check within taggroups.contents and filter out the item there too
        updatedData = updatedData.map(item => {
            if (item.type === 'taggroup' && item.contents) {
                return {
                    ...item,
                    contents: item.contents.filter(tag => tag.id !== itemId)
                };
            }
            return item;
        });

        // Update the state
        setTagFilter(updatedData);
    };

    const getItemListById = (id, data) => {
        if (id === 'top-level') return data;
        for (const item of data) {
            if (item.type === 'taggroup' && item.id == id) {
                return item.contents;
            }
            if (item.contents) {
                const found = getItemListById(id, item.contents);
                if (found) return found;
            }
        }
        return null;
    };


    const addTag = (listId) => {
        const newData = [...tagFilter];

        const listToAddTo = getItemListById(listId, newData);

        const newItem = {
            id: `tag-${uuidv4()}`,
            type: 'tag',
            tag: '',
            operation: 'OR'
        };

        listToAddTo.push(newItem);

        setTagFilter(newData);
    };

    const addTagGroup = (listId) => {
        const newData = [...tagFilter];

        const listToAddTo = getItemListById(listId, newData);

        const newItem = {
            id: `taggroup-${uuidv4()}`,
            type: 'taggroup',
            contents: []
        };

        listToAddTo.push(newItem);

        setTagFilter(newData);
    };

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <div
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
            style={{
                float: 'right',
                cursor: 'pointer',
                width: '24px',
                height: '24px',
                borderRadius: '50%',
                backgroundColor: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            {children}
        </div>
    ));

    const handleDropdownSelection = (key, listId) => {
        switch (key) {
            case '1':
                // Handle "Tag" selection
                addTag(listId);
                break;
            case '2':
                // Handle "Tag Group" selection
                addTagGroup(listId);
                break;
            default:
                break;
        }
    };


    return (

        <Container fluid style={{ width: '100%', backgroundColor: '#f8f8f8', paddingTop: '20px', paddingBottom: '20px', marginBottom: '50px' }}>
            <Row>
                <Col md={{ span: 1, offset: 11 }} xs={{ span: 2, offset: 10 }} className="text-end">
                    <Dropdown onSelect={(eventKey) => handleDropdownSelection(eventKey, 'top-level')}>
                        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                            <FontAwesomeIcon icon={faPlus} />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item eventKey="1">Tag</Dropdown.Item>
                            <Dropdown.Item eventKey="2">Tag Group</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
            </Row>
            <Row>
                <Col lg={12} id="dnd-portal">
                    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
                        <StrictModeDroppable droppableId="top-level" key="top-level" type="DRAG">
                            {provided => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    {tagFilter.length === 0 ? (
                                        <h2 className='empty-tag-group'>No filter criteria specified</h2>
                                    ) : (
                                        tagFilter.map((item, index) =>
                                            item.type === 'tag' ? (
                                                <TagItem
                                                    key={item.id}
                                                    index={index}
                                                    item={item}
                                                    currentDraggedItemId={currentDraggedItemId}
                                                    handleTagChange={handleTagChange}
                                                    handleConditionChange={handleConditionChange}
                                                    handleOperationChange={handleOperationChange}
                                                    deleteItem={deleteItem} />
                                            ) : item.type === 'taggroup' ? (
                                                <TagList
                                                    key={item.id}
                                                    index={index}
                                                    item={item}
                                                    currentDraggedItemId={currentDraggedItemId}
                                                    handleTagChange={handleTagChange}
                                                    handleConditionChange={handleConditionChange}
                                                    handleOperationChange={handleOperationChange}
                                                    deleteItem={deleteItem}
                                                    addTag={addTag} />
                                            ) : null
                                        )

                                    )}
                                    {provided.placeholder}
                                </div>
                            )}
                        </StrictModeDroppable>
                    </DragDropContext>
                </Col>
            </Row>
        </Container>

    );
};

StrictModeDroppable.propTypes = {
    children: PropTypes.any
};

export default TagSelector;