import { useState } from 'react';
import IconButton from 'components/common/IconButton';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row, Button, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ToneOfVoiceQuickCreate from './ToneOfVoiceQuickCreate.js';

const ToneOfVoiceListTableHeader = ({ selectedRowIds, loadData }) => {
  const navigate = useNavigate();

  const [modal, setModal] = useState(false);

  const handleShowModal = () => {
    setModal(true);
  }

  const handleCreateNew = () => {
    navigate('/tone-of-voice/new');
  }

  return (
    <>
      <Row className="flex-between-center">
        <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
          <h4 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Tone of Voices</h4>
        </Col>
        <Col xs={8} sm="auto" className="ms-auto text-end ps-0">
          <div id="orders-actions">
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="plus"
              transform="shrink-3"
              className="mx-3"
              onClick={handleShowModal}
            >
              <span className="d-none d-sm-inline-block ms-1">Quick Create</span>
            </IconButton>
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="plus"
              transform="shrink-3"
              onClick={handleCreateNew}
            >
              <span className="d-none d-sm-inline-block ms-1">Create tone of voice</span>
            </IconButton>
          </div>
        </Col>
      </Row>
      <ToneOfVoiceQuickCreate modal={modal} setModal={setModal} loadData={loadData} />
    </>
  );
};

ToneOfVoiceListTableHeader.propTypes = {
  selectedRowIds: PropTypes.object,
  loadData: PropTypes.func.isRequired
};

export default ToneOfVoiceListTableHeader;
